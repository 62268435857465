import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
};

export type Activity = {
  __typename?: 'Activity';
  activity_type?: Maybe<Scalars['String']>;
  activitykey?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  category?: Maybe<Scalars['String']>;
  category_number: Scalars['Float'];
  cpd_id?: Maybe<Scalars['String']>;
  cpd_types?: Maybe<Array<Maybe<CpdType>>>;
  cpr: Scalars['Boolean'];
  cycle?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Activity exemption. */
  exempt: Scalars['Boolean'];
  guid?: Maybe<Scalars['String']>;
  history_record_type?: Maybe<Scalars['String']>;
  hours: Scalars['Float'];
  /** Flag if Acitvity is backdated */
  is_backdated: Scalars['Boolean'];
  is_racgp_content: Scalars['Boolean'];
  log_id?: Maybe<Scalars['String']>;
  /** The Id of the member. */
  memberid?: Maybe<Scalars['ID']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  pdp: Scalars['Boolean'];
  pdp_comments?: Maybe<Scalars['String']>;
  pdp_year?: Maybe<Scalars['String']>;
  points: Scalars['Float'];
  prefix?: Maybe<Scalars['String']>;
  program_levels?: Maybe<Array<Scalars['String']>>;
  provider_name?: Maybe<Scalars['String']>;
  qi?: Maybe<Scalars['String']>;
  req_eligibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  triennium?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  type_of_cpd?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  address_1?: Maybe<Scalars['String']>;
  address_2?: Maybe<Scalars['String']>;
  address_3?: Maybe<Scalars['String']>;
  address_type?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

export enum AttType {
  AttendanceCertificate = 'attendance_certificate',
  GeneratedAttachment = 'generated_attachment',
  Hlrattachments = 'hlrattachments',
  PdpAttachment = 'pdp_attachment',
  Quicklogattachments = 'quicklogattachments'
}

export type Attachment = {
  __typename?: 'Attachment';
  attachment_name?: Maybe<Scalars['String']>;
  attachment_type: AttType;
  retrieval_key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type AttachmentInput = {
  attachment_name?: InputMaybe<Scalars['String']>;
  attachment_type: AttType;
  retrieval_key?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type Bookmark = {
  __typename?: 'Bookmark';
  bookmarkkey?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['String']>;
  internal_type?: Maybe<Scalars['String']>;
  /** The Id of the member. */
  memberid?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Represents category group requirement and fullfilment. Each category group requirement is child of a requirement. */
export type CategoryGroupRequirement = {
  __typename?: 'CategoryGroupRequirement';
  /** Category group code e.g "RPMO" */
  category_group_code: Scalars['String'];
  /** Collection of category requirements */
  category_requirements?: Maybe<Array<Maybe<CategoryRequirement>>>;
  /** Category group requirement is set to be completed if minimum &gt; 0 and hours &gt;= minimum (applies to hourly_calculation types) */
  completed: Scalars['Boolean'];
  /** Description */
  description: Scalars['String'];
  /** Group total hours */
  hours: Scalars['Float'];
  /** Maximum hours allowed for this group (if set to 0, there is not limit) */
  maximum: Scalars['Float'];
  /** Minimum hours required for the group to be completed */
  minimum: Scalars['Float'];
  /** Priority of evaluation and fullfilment of each group */
  priority: Scalars['Int'];
  program_levels?: Maybe<Array<Scalars['String']>>;
};

/** Represents category requirement and fullfilment. Each component is child of a category group requirement. */
export type CategoryRequirement = {
  __typename?: 'CategoryRequirement';
  /** Category code name e.g "EA" */
  category_code: Scalars['String'];
  /** Category requirement is set to be completed if minimum &gt; 0 and hours &gt;= minimum */
  completed: Scalars['Boolean'];
  /** Description */
  description: Scalars['String'];
  /** Maximum hours allowed for this component */
  hours: Scalars['Float'];
  /** Maximum hours allowed for this component (if set to 0, there is not limit) */
  maximum: Scalars['Float'];
  /** Minimum hours required for the component to be completed */
  minimum: Scalars['Float'];
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type ContentSearchItem = {
  __typename?: 'ContentSearchItem';
  content?: Maybe<CpdContent>;
  score: Scalars['Float'];
};

export type ContentSearchResult = {
  __typename?: 'ContentSearchResult';
  items?: Maybe<Array<Maybe<ContentSearchItem>>>;
  total: Scalars['Long'];
};

export type CpdActivityContact = {
  __typename?: 'CpdActivityContact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CpdActivityContent = {
  __typename?: 'CpdActivityContent';
  attendees?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  contact?: Maybe<CpdActivityContact>;
  curriculumContextualUnits?: Maybe<Array<Maybe<CpdActivityCurriculumContextualUnit>>>;
  deliveryMode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  eligibleForGrantsYesNo?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  gpDomains?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasAnaestheticGrant: Scalars['Boolean'];
  hasEmergencyMedicineGrant: Scalars['Boolean'];
  hasObstetricGrant: Scalars['Boolean'];
  hasSurgeryGrant: Scalars['Boolean'];
  hours: Scalars['Float'];
  hoursGroup?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  learningOutcomes?: Maybe<Array<Maybe<Scalars['String']>>>;
  programLevels?: Maybe<Array<Maybe<Scalars['String']>>>;
  providerName?: Maybe<Scalars['String']>;
  providerType?: Maybe<Scalars['String']>;
  relevance?: Maybe<Scalars['String']>;
  requirements?: Maybe<Array<Maybe<CpdActivityRequirement>>>;
  ruralCpdYesNo?: Maybe<Scalars['String']>;
  sessionSeriesEndDate?: Maybe<Scalars['DateTime']>;
  sessionSeriesStartDate?: Maybe<Scalars['DateTime']>;
  sessions?: Maybe<Array<Maybe<CpdActivitySession>>>;
  specificRequirements?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Scalars['String']>;
  statusEffectiveDate?: Maybe<Scalars['DateTime']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  targetAudience?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CpdActivityCurriculumContextualUnit = {
  __typename?: 'CpdActivityCurriculumContextualUnit';
  id: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
  otherInfo?: Maybe<Scalars['String']>;
};

export type CpdActivityPlan = {
  __typename?: 'CpdActivityPlan';
  hours?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
};

export type CpdActivityPlanInput = {
  hours?: InputMaybe<Scalars['Float']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type?: InputMaybe<Scalars['String']>;
};

export type CpdActivityRequirement = {
  __typename?: 'CpdActivityRequirement';
  hours: Scalars['Float'];
  name?: Maybe<Scalars['String']>;
};

export type CpdActivitySession = {
  __typename?: 'CpdActivitySession';
  eventId: Scalars['Float'];
  fromDateTime: Scalars['DateTime'];
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  toDateTime: Scalars['DateTime'];
  venue?: Maybe<Scalars['String']>;
};

export type CpdContent = {
  __typename?: 'CpdContent';
  activity_type?: Maybe<Scalars['String']>;
  anaesthetic_grant?: Maybe<Scalars['Boolean']>;
  attendees?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  chapter_number?: Maybe<Scalars['String']>;
  chapter_pdf?: Maybe<Scalars['String']>;
  contact?: Maybe<Contact>;
  content?: Maybe<Array<Maybe<Scalars['String']>>>;
  cpd_types?: Maybe<Array<Maybe<CpdType>>>;
  cpr: Scalars['Float'];
  curriculum_contextual_units?: Maybe<Array<Maybe<CurriculumContextualUnit>>>;
  delivery_mode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emergency_med_grant?: Maybe<Scalars['Boolean']>;
  endorsed_accepted_link_text?: Maybe<Scalars['String']>;
  endorsed_accepted_link_url?: Maybe<Scalars['String']>;
  endorsed_accepted_type?: Maybe<Scalars['Float']>;
  entitytype?: Maybe<Scalars['String']>;
  frequency?: Maybe<Scalars['String']>;
  gp_domains?: Maybe<Array<Maybe<GpDomain>>>;
  guidelines_detail_id?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Float']>;
  is_feature1?: Maybe<Scalars['Boolean']>;
  is_feature2?: Maybe<Scalars['Boolean']>;
  is_feature3?: Maybe<Scalars['Boolean']>;
  is_racgp_content?: Maybe<Scalars['Boolean']>;
  learning_outcome?: Maybe<Array<Maybe<Scalars['String']>>>;
  menu_item_group?: Maybe<Scalars['String']>;
  obstetric_grant?: Maybe<Scalars['Boolean']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  partnering_organisations?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Float']>;
  program_levels?: Maybe<Array<Maybe<Scalars['String']>>>;
  provider_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  relevance?: Maybe<Scalars['String']>;
  req_eligibility?: Maybe<Array<Maybe<Scalars['String']>>>;
  session_series_end_date?: Maybe<Scalars['String']>;
  session_series_start_date?: Maybe<Scalars['String']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  status_active?: Maybe<Scalars['Boolean']>;
  status_effective_date?: Maybe<Scalars['DateTime']>;
  status_of_activity?: Maybe<Scalars['String']>;
  surgery_grant?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Scalars['String']>;
  target_audience?: Maybe<Scalars['String']>;
  third_party_sponsors?: Maybe<Scalars['String']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  triennium?: Maybe<Scalars['String']>;
  type_of_cpd?: Maybe<Scalars['String']>;
  upload_content_urls?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
};

export type CpdSummary = {
  __typename?: 'CpdSummary';
  accredited?: Maybe<Scalars['Float']>;
  basic_live_skills?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Float']>;
  total_hours?: Maybe<Scalars['Float']>;
  triennium?: Maybe<Scalars['String']>;
  type_of_cpd_split?: Maybe<Array<Maybe<CpdType>>>;
};

export type CpdType = {
  __typename?: 'CpdType';
  hours?: Maybe<Scalars['Float']>;
  type?: Maybe<Scalars['String']>;
};

export type CpdTypeInput = {
  hours?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type CpdUpdateDetails = {
  __typename?: 'CpdUpdateDetails';
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CurriculumContextualUnit = {
  __typename?: 'CurriculumContextualUnit';
  curriculum_area_id?: Maybe<Scalars['Float']>;
  curriculum_area_name?: Maybe<Scalars['String']>;
  other_info?: Maybe<Scalars['String']>;
};

export type Cycle = {
  __typename?: 'Cycle';
  close_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  name: Scalars['String'];
  start_date: Scalars['DateTime'];
};

export type DashboardPreferenceItem = {
  __typename?: 'DashboardPreferenceItem';
  activityYear: Scalars['Int'];
  isDualFellowshipEnabled: Scalars['Boolean'];
  isSpecificRequirementEnabled: Scalars['Boolean'];
};

export type Facet = {
  __typename?: 'Facet';
  name?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<FacetValue>>>;
};

export type FacetValue = {
  __typename?: 'FacetValue';
  count: Scalars['Long'];
  value?: Maybe<Scalars['String']>;
};

export type FilterFieldInput = {
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type GpDomain = {
  __typename?: 'GpDomain';
  domain_comm_skills?: Maybe<Scalars['String']>;
  domain_ethic_role?: Maybe<Scalars['String']>;
  domain_legal_dim?: Maybe<Scalars['String']>;
  domain_pop_health?: Maybe<Scalars['String']>;
  domain_prof_knowl?: Maybe<Scalars['String']>;
  domain_rural_health?: Maybe<Scalars['String']>;
  domain_torres_health?: Maybe<Scalars['String']>;
};

export type HlrActivityTypeDateRange = {
  __typename?: 'HLRActivityTypeDateRange';
  activity_type_code: Scalars['String'];
  activity_type_hlr_content?: Maybe<Scalars['String']>;
  activity_type_hlr_heading?: Maybe<Scalars['String']>;
  activity_type_hlr_specialist_college?: Maybe<Scalars['String']>;
  activity_type_hlr_specialist_college_phone?: Maybe<Scalars['String']>;
  activity_type_hlr_specialist_college_website?: Maybe<Scalars['String']>;
  activity_type_id: Scalars['Int'];
  activitykey?: Maybe<Scalars['String']>;
  end_date: Scalars['String'];
  start_date: Scalars['String'];
  status?: Maybe<HlrStatus>;
  title: Scalars['String'];
};

export type HlrActivityTypeDateRangeInput = {
  activity_type_code: Scalars['String'];
  activity_type_hlr_content?: InputMaybe<Scalars['String']>;
  activity_type_hlr_heading?: InputMaybe<Scalars['String']>;
  activity_type_hlr_specialist_college?: InputMaybe<Scalars['String']>;
  activity_type_hlr_specialist_college_phone?: InputMaybe<Scalars['String']>;
  activity_type_hlr_specialist_college_website?: InputMaybe<Scalars['String']>;
  activity_type_id: Scalars['Int'];
  activitykey?: InputMaybe<Scalars['String']>;
  end_date: Scalars['String'];
  start_date: Scalars['String'];
  status?: InputMaybe<HlrStatus>;
  title: Scalars['String'];
};

export type HlrActivityTypeDateRangeList = {
  __typename?: 'HLRActivityTypeDateRangeList';
  items?: Maybe<Array<Maybe<HlrActivityTypeDateRange>>>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
};

export enum HlrStatus {
  HlrDraft = 'hlr_draft',
  HlrSubmitted = 'hlr_submitted'
}

export type HighLevelRequirementDetail = {
  __typename?: 'HighLevelRequirementDetail';
  activity_type?: Maybe<Scalars['String']>;
  activity_type_hlr_content?: Maybe<Scalars['String']>;
  activity_type_hlr_heading?: Maybe<Scalars['String']>;
  activity_type_hlr_specialist_college?: Maybe<Scalars['String']>;
  activity_type_hlr_specialist_college_phone?: Maybe<Scalars['String']>;
  activity_type_hlr_specialist_college_website?: Maybe<Scalars['String']>;
  activity_type_id: Scalars['Int'];
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  category?: Maybe<Scalars['String']>;
  category_number: Scalars['Float'];
  cpd_id: Scalars['Int'];
  cpd_types?: Maybe<Array<Maybe<CpdType>>>;
  cycle?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date: Scalars['DateTime'];
  history_record_type?: Maybe<Scalars['String']>;
  hlr_status?: Maybe<HlrStatus>;
  hours?: Maybe<Scalars['Float']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  start_date: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  triennium?: Maybe<Scalars['String']>;
  type_of_cpd?: Maybe<Scalars['String']>;
};

export type InputActivityCommentsInput = {
  activitykey?: InputMaybe<Scalars['String']>;
  pdp_comments?: InputMaybe<Scalars['String']>;
};

export type ItemsResultOfActivity = {
  __typename?: 'ItemsResultOfActivity';
  items?: Maybe<Array<Maybe<Activity>>>;
  nextToken?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type ItemsResultOfAttachment = {
  __typename?: 'ItemsResultOfAttachment';
  items?: Maybe<Array<Maybe<Attachment>>>;
  nextToken?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type ItemsResultOfBookmark = {
  __typename?: 'ItemsResultOfBookmark';
  items?: Maybe<Array<Maybe<Bookmark>>>;
  nextToken?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type ItemsResultOfLearningInterest = {
  __typename?: 'ItemsResultOfLearningInterest';
  items?: Maybe<Array<Maybe<LearningInterest>>>;
  nextToken?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type ItemsResultOfLookup = {
  __typename?: 'ItemsResultOfLookup';
  items?: Maybe<Array<Maybe<Lookup>>>;
  nextToken?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
};

export type LearningInterest = {
  __typename?: 'LearningInterest';
  learning_interest?: Maybe<Scalars['String']>;
  learning_interest_id?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type LearningInterestInput = {
  learning_interest?: InputMaybe<Scalars['String']>;
  learning_interest_id?: InputMaybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type Lookup = {
  __typename?: 'Lookup';
  key?: Maybe<Scalars['String']>;
  lookup_type?: Maybe<Scalars['String']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  sort_order?: Maybe<Scalars['Float']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Member = {
  __typename?: 'Member';
  address?: Maybe<Array<Maybe<Address>>>;
  cpd_summary?: Maybe<Array<Maybe<CpdSummary>>>;
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  /** The Id of the member. */
  memberid?: Maybe<Scalars['ID']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
};

export type MemberAttachment = {
  __typename?: 'MemberAttachment';
  attachmentKey?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  memberid?: Maybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Add High level requirements for a member
   *
   *
   * **Returns:**
   * High level requirement details
   */
  addHighlevelRequirement?: Maybe<HighLevelRequirementDetail>;
  /**
   * Add or update notification subscription for a member
   *
   *
   * **Returns:**
   * Collection of notifications
   */
  addNotification?: Maybe<NotificationList>;
  /** back date an activity */
  backdateActivity?: Maybe<Activity>;
  /**
   * Delete all bookmarks for triennium for a member
   *
   *
   * **Returns:**
   * Collection of bookmarks
   */
  deleteAllBookmarks?: Maybe<ItemsResultOfBookmark>;
  /**
   * Delete a bookmark for a member
   *
   *
   * **Returns:**
   * Bookmark
   */
  deleteBookmark?: Maybe<Bookmark>;
  /**
   * Delete quick log activity details for a member
   *
   *
   * **Returns:**
   * Activity
   */
  deleteHlrActivity?: Maybe<HighLevelRequirementDetail>;
  /**
   * Delete attachments for a member
   *
   *
   * **Returns:**
   * Member attachment
   */
  deleteMemberAttachments?: Maybe<MemberAttachment>;
  /**
   * Delete notifications for a member
   *
   *
   * **Returns:**
   * Collection of notifications
   */
  deleteNotifications?: Maybe<NotificationList>;
  /**
   * Delete PDP attachments for a member
   *
   *
   * **Returns:**
   * Collection of PDP attachments
   */
  deletePdpAttachments?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * Delete quick log activity details for a member
   *
   *
   * **Returns:**
   * Activity
   */
  deleteQuickLogActivity?: Maybe<Activity>;
  /**
   * Save bookmark for a member
   *
   *
   * **Returns:**
   * Bookmark
   */
  saveBookmark?: Maybe<Bookmark>;
  /** Submit High level requirements for a member */
  saveHighlevelRequirement?: Maybe<HighLevelRequirementDetail>;
  /**
   * Save learning interests for a member
   *
   *
   * **Returns:**
   * Collection of learning interests
   */
  saveLearningInterests?: Maybe<ItemsResultOfLearningInterest>;
  /**
   * Save activity for a member
   *
   *
   * **Returns:**
   * Collection of activities
   */
  saveMemberActivity?: Maybe<ItemsResultOfActivity>;
  /**
   * Save attachment for a member
   *
   *
   * **Returns:**
   * Member attachment
   */
  saveMemberAttachments?: Maybe<MemberAttachment>;
  /**
   * Save notification preferences for a member
   *
   *
   * **Returns:**
   * preferences
   */
  saveNotificationPreferences?: Maybe<Preference>;
  /**
   * Save PDP details for a member
   *
   *
   * **Returns:**
   * PDP details
   */
  savePdpDetails?: Maybe<PdpDetail>;
  /**
   * Save quick log for a member
   *
   *
   * **Returns:**
   * Activity
   */
  saveQuickLogActivity?: Maybe<Activity>;
  /**
   * Submit PDP details for a member
   *
   *
   * **Returns:**
   * PDP details
   */
  submitPdp?: Maybe<PdpDetail>;
  /**
   * Update notifications subscriptions for a member
   *
   *
   * **Returns:**
   * Collection of notifications
   */
  updateNotifications?: Maybe<NotificationList>;
  /**
   * Add/ update Dual fellowship dashboard preference
   *
   *
   * **Returns:**
   * returns dashboard preference
   */
  upsertDualFellowshipPreference?: Maybe<DashboardPreferenceItem>;
  /**
   * Add/ update Specific requirement dashboard preference
   *
   *
   * **Returns:**
   * returns dashboard preference
   */
  upsertSpecificRequirementPreference?: Maybe<DashboardPreferenceItem>;
};


export type MutationAddHighlevelRequirementArgs = {
  asAtDate?: InputMaybe<Scalars['String']>;
  hlrdetails?: InputMaybe<HlrActivityTypeDateRangeInput>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationAddNotificationArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  notification?: InputMaybe<SessionNotificationSubscriptionInput>;
};


export type MutationBackdateActivityArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteAllBookmarksArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteBookmarkArgs = {
  bookmarkkey?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteHlrActivityArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteMemberAttachmentsArgs = {
  attachmentKey?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteNotificationsArgs = {
  deleteAll?: Scalars['Boolean'];
  memberid?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<InputMaybe<SubscriptionInput>>>;
};


export type MutationDeletePdpAttachmentsArgs = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteQuickLogActivityArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationSaveBookmarkArgs = {
  internal_id?: InputMaybe<Scalars['String']>;
  internal_type?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type MutationSaveHighlevelRequirementArgs = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  cpd_types?: InputMaybe<Array<InputMaybe<CpdTypeInput>>>;
  hlrDetails?: InputMaybe<HlrActivityTypeDateRangeInput>;
  memberId?: InputMaybe<Scalars['String']>;
};


export type MutationSaveLearningInterestsArgs = {
  learning_interests?: InputMaybe<Array<InputMaybe<LearningInterestInput>>>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationSaveMemberActivityArgs = {
  details?: InputMaybe<InputActivityCommentsInput>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationSaveMemberAttachmentsArgs = {
  attachmentKey?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type MutationSaveNotificationPreferencesArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  notification_preferences?: InputMaybe<PreferenceInput>;
};


export type MutationSavePdpDetailsArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  pdp_details?: InputMaybe<PdpDetailInput>;
  pdp_year?: InputMaybe<Scalars['String']>;
};


export type MutationSaveQuickLogActivityArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  cpd_types?: InputMaybe<Array<InputMaybe<CpdTypeInput>>>;
  date?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  hours: Scalars['Float'];
  memberid?: InputMaybe<Scalars['String']>;
  points: Scalars['Int'];
  program_levels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  type_of_cpd?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitPdpArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateNotificationsArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<InputMaybe<SubscriptionInput>>>;
};


export type MutationUpsertDualFellowshipPreferenceArgs = {
  activityYear: Scalars['Int'];
  isDualFellowship: Scalars['Boolean'];
  memberId?: InputMaybe<Scalars['String']>;
};


export type MutationUpsertSpecificRequirementPreferenceArgs = {
  activityYear: Scalars['Int'];
  isSpecficRequirement: Scalars['Boolean'];
  memberId?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  cpd_update_details?: Maybe<CpdUpdateDetails>;
  notification_status?: Maybe<NotificationStatus>;
  pdp_alert_details?: Maybe<PdpAlert>;
  reference_id?: Maybe<Scalars['String']>;
  session_details?: Maybe<CpdContent>;
  startDate?: Maybe<Scalars['DateTime']>;
  type: NotificationType;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  items?: Maybe<Array<Maybe<Notification>>>;
  total: Scalars['Int'];
  unread: Scalars['Int'];
};

export enum NotificationStatus {
  Dismissed = 'dismissed',
  Read = 'read',
  Unread = 'unread'
}

export enum NotificationType {
  CpdUpdate = 'cpd_update',
  PdpAlert = 'pdp_alert',
  SessionReminder = 'session_reminder'
}

export type PdpAlert = {
  __typename?: 'PdpAlert';
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PdpDetail = {
  __typename?: 'PdpDetail';
  /** Annual review and reflection. */
  annual_review_and_reflection?: Maybe<Scalars['String']>;
  /** PDP attachments from 2023. */
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  /** Commented activities till 2022. */
  commented_activities?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** CPD activity plan from 2023. */
  cpdActivityPlans?: Maybe<Array<Maybe<CpdActivityPlan>>>;
  cpd_activity_key?: Maybe<Scalars['String']>;
  /** Current work situation from 2023. */
  current_situation?: Maybe<Scalars['String']>;
  /** PDP exemption from 2023. */
  exempt?: Maybe<Scalars['Boolean']>;
  /** General comments till 2022. */
  general_comments?: Maybe<Scalars['String']>;
  /** Learing goals. */
  goals?: Maybe<Scalars['String']>;
  /** Hours spent on PDP. */
  hours_spent?: Maybe<Scalars['Float']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  pdp_status?: Maybe<PdpStatus>;
  /** PDP type from 2023. */
  pdp_type?: Maybe<PdpType>;
  pdp_year?: Maybe<Scalars['String']>;
  program_levels?: Maybe<Array<Scalars['String']>>;
  /** Quick log reflection till 2022. */
  quick_log_reflection_comments?: Maybe<Scalars['String']>;
  /** Reflection on culturally save practice from 2023. */
  reflection_culturally_save_practice?: Maybe<Scalars['String']>;
  /** Reflection on health inequities from 2024. */
  reflection_health_inequities?: Maybe<Scalars['String']>;
  /** Reflection on professionalism and ethical practice from 2024. */
  reflection_professionalism_ethical_practice?: Maybe<Scalars['String']>;
  /** Scope of practice till 2022. */
  scope_of_practice?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
};

export type PdpDetailInput = {
  /** Annual review and reflection. */
  annual_review_and_reflection?: InputMaybe<Scalars['String']>;
  /** PDP attachments from 2023. */
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  /** Commented activities till 2022. */
  commented_activities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** CPD activity plan from 2023. */
  cpdActivityPlans?: InputMaybe<Array<InputMaybe<CpdActivityPlanInput>>>;
  cpd_activity_key?: InputMaybe<Scalars['String']>;
  /** Current work situation from 2023. */
  current_situation?: InputMaybe<Scalars['String']>;
  /** PDP exemption from 2023. */
  exempt?: InputMaybe<Scalars['Boolean']>;
  /** General comments till 2022. */
  general_comments?: InputMaybe<Scalars['String']>;
  /** Learing goals. */
  goals?: InputMaybe<Scalars['String']>;
  /** Hours spent on PDP. */
  hours_spent?: InputMaybe<Scalars['Float']>;
  /** The partition key of the record. */
  partitionkey?: InputMaybe<Scalars['String']>;
  pdp_status?: InputMaybe<PdpStatus>;
  /** PDP type from 2023. */
  pdp_type?: InputMaybe<PdpType>;
  pdp_year?: InputMaybe<Scalars['String']>;
  program_levels?: InputMaybe<Array<Scalars['String']>>;
  /** Quick log reflection till 2022. */
  quick_log_reflection_comments?: InputMaybe<Scalars['String']>;
  /** Reflection on culturally save practice from 2023. */
  reflection_culturally_save_practice?: InputMaybe<Scalars['String']>;
  /** Reflection on health inequities from 2024. */
  reflection_health_inequities?: InputMaybe<Scalars['String']>;
  /** Reflection on professionalism and ethical practice from 2024. */
  reflection_professionalism_ethical_practice?: InputMaybe<Scalars['String']>;
  /** Scope of practice till 2022. */
  scope_of_practice?: InputMaybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: InputMaybe<Scalars['String']>;
};

export type PdpReference = {
  __typename?: 'PdpReference';
  activity_from_date?: Maybe<Scalars['String']>;
  activity_to_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  pdp_year?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  submit_from_date?: Maybe<Scalars['String']>;
  submit_to_date?: Maybe<Scalars['String']>;
};

export type PdpReferenceList = {
  __typename?: 'PdpReferenceList';
  items?: Maybe<Array<Maybe<PdpReference>>>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
};

export enum PdpStatus {
  PdpDraft = 'pdp_draft',
  PdpSubmitted = 'pdp_submitted'
}

export enum PdpType {
  Template = 'template',
  Upload = 'upload'
}

export type PlrDetail = {
  __typename?: 'PlrDetail';
  end_date: Scalars['DateTime'];
  memberid?: Maybe<Scalars['String']>;
  plrCard_status?: Maybe<PlrStatus>;
  plrItem?: Maybe<Array<Maybe<PlrItem>>>;
  start_date: Scalars['DateTime'];
};

export type PlrItem = {
  __typename?: 'PlrItem';
  plr?: Maybe<Scalars['String']>;
};

export enum PlrStatus {
  PlrComplete = 'plr_complete',
  PlrIncomplete = 'plr_incomplete'
}

export type Preference = {
  __typename?: 'Preference';
  cpd_updates: Scalars['Boolean'];
  pdp_alerts: Scalars['Boolean'];
  session_reminders?: Maybe<SessionReminder>;
};

export type PreferenceInput = {
  cpd_updates: Scalars['Boolean'];
  pdp_alerts: Scalars['Boolean'];
  session_reminders?: InputMaybe<SessionReminderInput>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Gets a list of date ranges for all activity types
   *
   *
   * **Returns:**
   * list of date ranges for all activity types
   */
  getActivityTypeDateRange?: Maybe<HlrActivityTypeDateRangeList>;
  /**
   * Gets attachment details for member using activity key
   *
   *
   * **Returns:**
   * Collection of attachment details
   */
  getAttendanceCertificateUrl?: Maybe<ItemsResultOfAttachment>;
  /**
   * Gets a list of bookmarks for a member
   *
   *
   * **Returns:**
   * A list of bookmarks
   */
  getBookmarks?: Maybe<ItemsResultOfBookmark>;
  getCompletionCertificate?: Maybe<ItemsResultOfAttachment>;
  /** Get CPD content by id and type */
  getContentById?: Maybe<CpdContent>;
  /**
   * Gets attachment details of cpd activity statement for member
   *
   *
   * **Returns:**
   * Attachment details
   */
  getCpdStatement?: Maybe<Attachment>;
  /**
   * Gets cycle details
   *
   *
   * **Returns:**
   * A cycle definition
   */
  getCycle?: Maybe<Cycle>;
  /**
   * Gets cycle details by date
   *
   *
   * **Returns:**
   * A cycle definition
   */
  getCycleByDate?: Maybe<Cycle>;
  /**
   * Gets dashboard preferences of a member for given activity year
   *
   *
   * **Returns:**
   * Dashboard preferences details
   */
  getDashboardPreference?: Maybe<DashboardPreferenceItem>;
  /**
   * Gets a list of attachments for member with generated retrieval keys with permission to write
   *
   *
   * **Returns:**
   * Collection of updated attachment details
   */
  getGeneratedUrlToUploadMemberAttachments?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * Gets learning interests for a member
   *
   *
   * **Returns:**
   * Collection of learning interests
   */
  getLearningInterests?: Maybe<ItemsResultOfLearningInterest>;
  /**
   * Gets member details
   *
   *
   * **Returns:**
   * A Member definition
   */
  getMember?: Maybe<Member>;
  /** Gets a list of member activities by cycle */
  getMemberActivities?: Maybe<ItemsResultOfActivity>;
  /**
   * Gets a list of member activities starting with "activitKey" e.g. "20-22#"
   *
   *
   * **Returns:**
   * A list of member activities
   * @deprecated For cycles starting 2023 'getMemberActivities' instead
   */
  getMemberActivity?: Maybe<ItemsResultOfActivity>;
  /** gets member activities based on cpdid */
  getMemberActivityByCpdId?: Maybe<Activity>;
  /**
   * Gets a member activity by activity key
   *
   *
   * **Returns:**
   * A member activity
   */
  getMemberActivityByKey?: Maybe<Activity>;
  /**
   * Gets a list of attachments for member with permission to read
   *
   *
   * **Returns:**
   * Collection of attachment details
   */
  getMemberAttachmentsUrls?: Maybe<Array<Maybe<Attachment>>>;
  /**
   * gets member hlr requirements which are active for given date
   *
   *
   * **Returns:**
   * active HLR details
   */
  getMemberHlrDetails?: Maybe<Array<Maybe<HighLevelRequirementDetail>>>;
  /** get member based HLR list with status (add/submit) for a given date */
  getMemberHlrSearch?: Maybe<HlrActivityTypeDateRangeList>;
  /**
   * Gets plr details for a member by year
   *
   *
   * **Returns:**
   * A member plr details
   */
  getMemberPlrDetail?: Maybe<PlrDetail>;
  /**
   * get specific requirements of the member for given activity year
   *
   *
   * **Returns:**
   * active specific requirement details
   */
  getMemberSpecificRequirementDetails?: Maybe<Array<Maybe<SpecificRequirementDetail>>>;
  /**
   * Gets minimum date for a quick log
   *
   *
   * **Returns:**
   * Minimum date string
   */
  getMinDateForQuickLog?: Maybe<Scalars['String']>;
  /**
   * Gets notification preferences for a member
   *
   *
   * **Returns:**
   * preferences details
   */
  getNotificationPreferences?: Maybe<Preference>;
  /**
   * Gets notifications for a member
   *
   *
   * **Returns:**
   * Collection of notifications
   */
  getNotificationSubscriptions?: Maybe<NotificationList>;
  /**
   * Gets notifications for a member
   *
   *
   * **Returns:**
   * Collection of notifications
   */
  getNotifications?: Maybe<NotificationList>;
  /**
   * Gets pdp details for a member by year
   *
   *
   * **Returns:**
   * Pdp details
   */
  getPdpDetails?: Maybe<PdpDetail>;
  /**
   * Gets a list of PDP references
   *
   *
   * **Returns:**
   * List of PDP references
   */
  getPdpReferences?: Maybe<PdpReferenceList>;
  /**
   * Gets a list of lookup key values
   *
   *
   * **Returns:**
   * A list of lookups
   */
  getReferenceLookup?: Maybe<ItemsResultOfLookup>;
  /**
   * Gets triennium details
   *
   *
   * **Returns:**
   * A triennium definition
   */
  getReferenceTriennium?: Maybe<Triennium>;
  /**
   * Gets triennium details by date
   *
   *
   * **Returns:**
   * A triennium definition
   */
  getReferenceTrienniumByDate?: Maybe<Triennium>;
  /**
   * Gets requirements summary details
   *
   *
   * **Returns:**
   * Requirements summary details
   */
  getRequirementsSummary?: Maybe<RequirementsSummary>;
  /**
   * Gets resource string
   *
   *
   * **Returns:**
   * Resource string
   */
  getResourceString?: Maybe<Scalars['String']>;
  /**
   * Gets resource string item
   *
   *
   * **Returns:**
   * Resource string
   */
  getResourceStringItem?: Maybe<ResourceString>;
  searchActivity?: Maybe<SearchActivityResult>;
  searchActivitySuggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Gets a list of bookmarks for a member using a search
   *
   *
   * **Returns:**
   * A list of bookmarks
   */
  searchBookmarks?: Maybe<ItemsResultOfBookmark>;
  /**
   * Search for content
   * @deprecated For CPD 2020-2022. Use searchActivity
   */
  searchContent?: Maybe<ContentSearchResult>;
  /**
   * Gets search suggestions based on search term
   *
   *
   * **Returns:**
   * Collection of suggestions
   * @deprecated For CPD 2020-2022. Use searchActivitySuggestions
   */
  searchSuggestions?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetActivityTypeDateRangeArgs = {
  dateAsAt?: InputMaybe<Scalars['String']>;
};


export type QueryGetAttendanceCertificateUrlArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetBookmarksArgs = {
  bookmarkkey?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  memberid?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
};


export type QueryGetCompletionCertificateArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
};


export type QueryGetContentByIdArgs = {
  entitytype?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryGetCpdStatementArgs = {
  asAtDate?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetCycleArgs = {
  cycleName?: InputMaybe<Scalars['String']>;
};


export type QueryGetCycleByDateArgs = {
  date?: InputMaybe<Scalars['String']>;
};


export type QueryGetDashboardPreferenceArgs = {
  activityYear: Scalars['Int'];
  memberId?: InputMaybe<Scalars['String']>;
};


export type QueryGetGeneratedUrlToUploadMemberAttachmentsArgs = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetLearningInterestsArgs = {
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberArgs = {
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberActivitiesArgs = {
  cycleName?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  memberid?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberActivityArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  memberid?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberActivityByCpdIdArgs = {
  cpdId?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberActivityByKeyArgs = {
  activitykey?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberAttachmentsUrlsArgs = {
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>>>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberHlrDetailsArgs = {
  asAtDate?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberHlrSearchArgs = {
  dateAsAt?: InputMaybe<Scalars['String']>;
  memberId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberPlrDetailArgs = {
  asAtDate?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetMemberSpecificRequirementDetailsArgs = {
  activityYear: Scalars['Int'];
  memberId?: InputMaybe<Scalars['String']>;
};


export type QueryGetNotificationPreferencesArgs = {
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetNotificationSubscriptionsArgs = {
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetNotificationsArgs = {
  from?: Scalars['Int'];
  limit?: Scalars['Int'];
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetPdpDetailsArgs = {
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
};


export type QueryGetReferenceLookupArgs = {
  lookupName?: InputMaybe<Scalars['String']>;
};


export type QueryGetReferenceTrienniumArgs = {
  triennium?: InputMaybe<Scalars['String']>;
};


export type QueryGetReferenceTrienniumByDateArgs = {
  date?: InputMaybe<Scalars['String']>;
};


export type QueryGetRequirementsSummaryArgs = {
  date?: InputMaybe<Scalars['String']>;
  memberid?: InputMaybe<Scalars['String']>;
};


export type QueryGetResourceStringArgs = {
  resourceStringName?: InputMaybe<Scalars['String']>;
};


export type QueryGetResourceStringItemArgs = {
  resourceStringName?: InputMaybe<Scalars['String']>;
};


export type QuerySearchActivityArgs = {
  request?: InputMaybe<SearchActivityInput>;
};


export type QuerySearchActivitySuggestionsArgs = {
  text?: InputMaybe<Scalars['String']>;
};


export type QuerySearchBookmarksArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
  memberid?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
};


export type QuerySearchContentArgs = {
  from: Scalars['Int'];
  limit: Scalars['Int'];
  memberid?: InputMaybe<Scalars['String']>;
  recommended?: InputMaybe<Scalars['Boolean']>;
  searchfields?: InputMaybe<Array<InputMaybe<SearchFieldInput>>>;
  searchterm?: InputMaybe<Scalars['String']>;
  sessiondaterange?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sessiondayofweek?: InputMaybe<Array<Scalars['Int']>>;
  sessionstate?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QuerySearchSuggestionsArgs = {
  searchterm?: InputMaybe<Scalars['String']>;
};

/** Represents business requirement details and fullfilment. Each requirement is child of requirements summary. */
export type Requirement = {
  __typename?: 'Requirement';
  /** Summary of all category codes used to fulfil this requirement */
  category_code_totals?: Maybe<Array<Maybe<CpdType>>>;
  /** Collection of category group requirements */
  category_group_requirements?: Maybe<Array<Maybe<CategoryGroupRequirement>>>;
  /** Requirement is set to be completed if all category group requirements are completed */
  completed: Scalars['Boolean'];
  /** Description */
  description: Scalars['String'];
  /** Requirement total hours */
  hours: Scalars['Float'];
  /** Total of all category group requirements minimum hours */
  minimum: Scalars['Float'];
  /** Requirement code e.g "RACGP" or "MBA" etc. */
  requirement_code: Scalars['String'];
  /** Requirement from date */
  requirement_from: Scalars['DateTime'];
  /** Requirement to date */
  requirement_to: Scalars['DateTime'];
};

/** Represents requirements summary and fullfilment of business requirements. */
export type RequirementsSummary = {
  __typename?: 'RequirementsSummary';
  /** CPD cycle e.g. "2023" */
  cycle: Scalars['String'];
  /** Date for which the requirements are set */
  date: Scalars['DateTime'];
  /** Member id */
  memberid: Scalars['String'];
  /** Collection of requirements */
  requirements?: Maybe<Array<Maybe<Requirement>>>;
  /** Total of all category group requirements */
  total_category_group_requirements: Scalars['Int'];
  /** Total of all completed category group requirements */
  total_completed_category_group_requirements: Scalars['Int'];
};

export type ResourceString = {
  __typename?: 'ResourceString';
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SearchActivityInput = {
  filters?: InputMaybe<Array<InputMaybe<FilterFieldInput>>>;
  from?: Scalars['Int'];
  includeFacets?: Scalars['Boolean'];
  limit?: Scalars['Int'];
  orderBy?: InputMaybe<Array<InputMaybe<SortFieldInput>>>;
  text?: InputMaybe<Scalars['String']>;
};

export type SearchActivityItem = {
  __typename?: 'SearchActivityItem';
  activity?: Maybe<CpdActivityContent>;
  score: Scalars['Float'];
};

export type SearchActivityResult = {
  __typename?: 'SearchActivityResult';
  facets?: Maybe<Array<Maybe<Facet>>>;
  items?: Maybe<Array<Maybe<SearchActivityItem>>>;
  total: Scalars['Long'];
};

export type SearchFieldInput = {
  key?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  eventid?: Maybe<Scalars['Float']>;
  from_date_time?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  to_date_time?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
};

export type SessionNotificationSubscriptionInput = {
  activity_id?: InputMaybe<Scalars['String']>;
  event_id?: InputMaybe<Scalars['String']>;
  type: NotificationType;
};

export type SessionReminder = {
  __typename?: 'SessionReminder';
  enabled: Scalars['Boolean'];
  remind_before_days: Scalars['Float'];
};

export type SessionReminderInput = {
  enabled: Scalars['Boolean'];
  remind_before_days: Scalars['Float'];
};

export type SortFieldInput = {
  name?: InputMaybe<Scalars['String']>;
  order: SortOrder;
};

export enum SortOrder {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING'
}

export type SpecificRequirementDetail = {
  __typename?: 'SpecificRequirementDetail';
  completionYear: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type SubscriptionInput = {
  notification_status?: InputMaybe<NotificationStatus>;
  reference_id?: InputMaybe<Scalars['String']>;
  type: NotificationType;
};

export type Triennium = {
  __typename?: 'Triennium';
  close_date?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  /** The partition key of the record. */
  partitionkey?: Maybe<Scalars['String']>;
  /** The sort key of the record. */
  sortkey?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type GetMemberCpdSummaryQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberCpdSummaryQuery = { __typename?: 'Query', getMember?: { __typename?: 'Member', cpd_summary?: Array<{ __typename?: 'CpdSummary', accredited?: number | null, basic_live_skills?: boolean | null, total?: number | null, total_hours?: number | null, triennium?: string | null, type_of_cpd_split?: Array<{ __typename?: 'CpdType', hours?: number | null, type?: string | null } | null> | null } | null> | null } | null };

export type AddHighlevelRequirementMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  asAtDate?: InputMaybe<Scalars['String']>;
  hlrdetails?: InputMaybe<HlrActivityTypeDateRangeInput>;
}>;


export type AddHighlevelRequirementMutation = { __typename?: 'Mutation', addHighlevelRequirement?: { __typename?: 'HighLevelRequirementDetail', activity_type_id: number, activity_type?: string | null, title?: string | null, start_date: any, end_date: any } | null };

export type AddNotificationMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  notification?: InputMaybe<SessionNotificationSubscriptionInput>;
}>;


export type AddNotificationMutation = { __typename?: 'Mutation', addNotification?: { __typename?: 'NotificationList', total: number, unread: number } | null };

export type BackdateActivityMutationVariables = Exact<{
  memberId?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
}>;


export type BackdateActivityMutation = { __typename?: 'Mutation', backdateActivity?: { __typename?: 'Activity', cpd_id?: string | null, is_backdated: boolean } | null };

export type DeleteAllBookmarksMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
}>;


export type DeleteAllBookmarksMutation = { __typename?: 'Mutation', deleteAllBookmarks?: { __typename?: 'ItemsResultOfBookmark', total: number } | null };

export type DeleteBookmarkMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  bookmarkkey?: InputMaybe<Scalars['String']>;
}>;


export type DeleteBookmarkMutation = { __typename?: 'Mutation', deleteBookmark?: { __typename?: 'Bookmark', memberid?: string | null, internal_id?: string | null, internal_type?: string | null, title?: string | null } | null };

export type DeleteHlrActivityMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
}>;


export type DeleteHlrActivityMutation = { __typename?: 'Mutation', deleteHlrActivity?: { __typename?: 'HighLevelRequirementDetail', title?: string | null, sortkey?: string | null } | null };

export type DeleteMemberAttachmentsMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>> | InputMaybe<AttachmentInput>>;
}>;


export type DeleteMemberAttachmentsMutation = { __typename?: 'Mutation', deleteMemberAttachments?: { __typename: 'MemberAttachment' } | null };

export type DeleteNotificationsMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<InputMaybe<SubscriptionInput>> | InputMaybe<SubscriptionInput>>;
  deleteAll?: Scalars['Boolean'];
}>;


export type DeleteNotificationsMutation = { __typename?: 'Mutation', deleteNotifications?: { __typename?: 'NotificationList', total: number, unread: number, items?: Array<{ __typename?: 'Notification', type: NotificationType, reference_id?: string | null, notification_status?: NotificationStatus | null, startDate?: any | null, cpd_update_details?: { __typename?: 'CpdUpdateDetails', title?: string | null, description?: string | null, start_date?: string | null, end_date?: string | null, url?: string | null } | null } | null> | null } | null };

export type DeleteQuickLogActivityMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
}>;


export type DeleteQuickLogActivityMutation = { __typename?: 'Mutation', deleteQuickLogActivity?: { __typename?: 'Activity', activitykey?: string | null } | null };

export type GetAttendanceCertificateUrlQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
}>;


export type GetAttendanceCertificateUrlQuery = { __typename?: 'Query', getAttendanceCertificateUrl?: { __typename: 'ItemsResultOfAttachment', items?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null };

export type GetBookmarksQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  bookmarkkey?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
}>;


export type GetBookmarksQuery = { __typename?: 'Query', getBookmarks?: { __typename?: 'ItemsResultOfBookmark', items?: Array<{ __typename?: 'Bookmark', sortkey?: string | null, internal_id?: string | null, partitionkey?: string | null, bookmarkkey?: string | null } | null> | null } | null };

export type GetContentByIdQueryVariables = Exact<{
  id: Scalars['String'];
  entitytype: Scalars['String'];
}>;


export type GetContentByIdQuery = { __typename?: 'Query', getContentById?: { __typename?: 'CpdContent', title?: Array<string | null> | null, description?: string | null, hours?: number | null, session_series_start_date?: string | null, session_series_end_date?: string | null, target_audience?: string | null, contact?: { __typename?: 'Contact', phone?: string | null, email?: string | null } | null } | null };

export type GetCpdStatementQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  asAtDate?: InputMaybe<Scalars['String']>;
}>;


export type GetCpdStatementQuery = { __typename?: 'Query', getCpdStatement?: { __typename?: 'Attachment', url?: string | null, attachment_name?: string | null } | null };

export type GetRequirementsSummaryQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
}>;


export type GetRequirementsSummaryQuery = { __typename?: 'Query', getRequirementsSummary?: { __typename: 'RequirementsSummary', cycle: string, memberid: string, date: any, total_category_group_requirements: number, total_completed_category_group_requirements: number, requirements?: Array<{ __typename?: 'Requirement', requirement_code: string, requirement_from: any, requirement_to: any, description: string, hours: number, completed: boolean, minimum: number, category_code_totals?: Array<{ __typename?: 'CpdType', hours?: number | null, type?: string | null } | null> | null, category_group_requirements?: Array<{ __typename?: 'CategoryGroupRequirement', category_group_code: string, description: string, minimum: number, maximum: number, hours: number, completed: boolean, program_levels?: Array<string> | null, category_requirements?: Array<{ __typename?: 'CategoryRequirement', category_code: string, description: string, minimum: number, maximum: number, hours: number, completed: boolean } | null> | null } | null> | null } | null> | null } | null };

export type GetCycleQueryVariables = Exact<{
  cycleName?: InputMaybe<Scalars['String']>;
}>;


export type GetCycleQuery = { __typename?: 'Query', getCycle?: { __typename?: 'Cycle', name: string, start_date: any, end_date: any, close_date: any } | null };

export type GetGeneratedUrlToUploadMemberAttachmentsQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>> | InputMaybe<AttachmentInput>>;
}>;


export type GetGeneratedUrlToUploadMemberAttachmentsQuery = { __typename?: 'Query', getGeneratedUrlToUploadMemberAttachments?: Array<{ __typename: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null };

export type GetLearningInterestsQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
}>;


export type GetLearningInterestsQuery = { __typename?: 'Query', getLearningInterests?: { __typename?: 'ItemsResultOfLearningInterest', total: number, items?: Array<{ __typename?: 'LearningInterest', learning_interest_id?: string | null, learning_interest?: string | null, status: boolean } | null> | null } | null };

export type GetMemberQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberQuery = { __typename?: 'Query', getMember?: { __typename?: 'Member', first_name?: string | null, last_name?: string | null, full_name?: string | null, memberid?: string | null, address?: Array<{ __typename?: 'Address', address_1?: string | null, address_2?: string | null, address_3?: string | null, address_type?: string | null, postcode?: string | null, state?: string | null, suburb?: string | null } | null> | null } | null };

export type GetMemberActivitiesQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  cycleName?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberActivitiesQuery = { __typename?: 'Query', getMemberActivities?: { __typename?: 'ItemsResultOfActivity', nextToken?: string | null, items?: Array<{ __typename: 'Activity', cpd_id?: string | null, activitykey?: string | null, activity_type?: string | null, history_record_type?: string | null, date?: string | null, cycle?: string | null, title?: string | null, type_of_cpd?: string | null, hours: number, provider_name?: string | null, description?: string | null, is_racgp_content: boolean, pdp_year?: string | null, type?: string | null, is_backdated: boolean, exempt: boolean, req_eligibility?: Array<string | null> | null, cpd_types?: Array<{ __typename?: 'CpdType', hours?: number | null, type?: string | null } | null> | null } | null> | null } | null };

export type GetMemberActivityByCpdIdQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  cpdId?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberActivityByCpdIdQuery = { __typename?: 'Query', getMemberActivityByCpdId?: { __typename?: 'Activity', activitykey?: string | null, hours: number, date?: string | null, title?: string | null, cpd_id?: string | null, program_levels?: Array<string> | null, description?: string | null, cpd_types?: Array<{ __typename?: 'CpdType', hours?: number | null, type?: string | null } | null> | null, attachments?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null };

export type GetMemberActivityByKeyQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
}>;


export type GetMemberActivityByKeyQuery = { __typename?: 'Query', getMemberActivityByKey?: { __typename?: 'Activity', activitykey?: string | null, hours: number, date?: string | null, title?: string | null, cpd_id?: string | null, description?: string | null, program_levels?: Array<string> | null, cpd_types?: Array<{ __typename?: 'CpdType', hours?: number | null, type?: string | null } | null> | null, attachments?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null };

export type GetMemberAttachmentUrlsQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>> | InputMaybe<AttachmentInput>>;
}>;


export type GetMemberAttachmentUrlsQuery = { __typename?: 'Query', getMemberAttachmentsUrls?: Array<{ __typename?: 'Attachment', url?: string | null, retrieval_key?: string | null, attachment_type: AttType, attachment_name?: string | null } | null> | null };

export type GetMemberHlrSearchQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['String']>;
  dateAsAt: Scalars['String'];
}>;


export type GetMemberHlrSearchQuery = { __typename?: 'Query', getMemberHlrSearch?: { __typename?: 'HLRActivityTypeDateRangeList', items?: Array<{ __typename?: 'HLRActivityTypeDateRange', activity_type_id: number, activity_type_code: string, title: string, start_date: string, end_date: string, status?: HlrStatus | null, activitykey?: string | null, activity_type_hlr_heading?: string | null, activity_type_hlr_specialist_college?: string | null, activity_type_hlr_specialist_college_phone?: string | null, activity_type_hlr_specialist_college_website?: string | null, activity_type_hlr_content?: string | null } | null> | null } | null };

export type GetMemberHlrDetailsQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['String']>;
  asAtDate: Scalars['String'];
}>;


export type GetMemberHlrDetailsQuery = { __typename?: 'Query', getMemberHlrDetails?: Array<{ __typename?: 'HighLevelRequirementDetail', title?: string | null, description?: string | null, cpd_id: number, activity_type_id: number, activity_type?: string | null, start_date: any, end_date: any, hlr_status?: HlrStatus | null, type_of_cpd?: string | null, hours?: number | null, cycle?: string | null, category?: string | null, category_number: number, date?: string | null, history_record_type?: string | null, triennium?: string | null, partitionkey?: string | null, sortkey?: string | null, cpd_types?: Array<{ __typename?: 'CpdType', hours?: number | null, type?: string | null } | null> | null, attachments?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null> | null };

export type GetMemberPlrDetailQueryVariables = Exact<{
  memberId?: InputMaybe<Scalars['String']>;
  asAtDate: Scalars['String'];
}>;


export type GetMemberPlrDetailQuery = { __typename?: 'Query', getMemberPlrDetail?: { __typename?: 'PlrDetail', plrCard_status?: PlrStatus | null, start_date: any, end_date: any, plrItem?: Array<{ __typename?: 'PlrItem', plr?: string | null } | null> | null } | null };

export type GetNotificationPreferencesQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
}>;


export type GetNotificationPreferencesQuery = { __typename?: 'Query', getNotificationPreferences?: { __typename?: 'Preference', cpd_updates: boolean, session_reminders?: { __typename?: 'SessionReminder', enabled: boolean, remind_before_days: number } | null } | null };

export type GetNotificationSubscriptionsQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
}>;


export type GetNotificationSubscriptionsQuery = { __typename?: 'Query', getNotificationSubscriptions?: { __typename?: 'NotificationList', total: number, unread: number, items?: Array<{ __typename?: 'Notification', type: NotificationType, reference_id?: string | null, notification_status?: NotificationStatus | null } | null> | null } | null };

export type GetNotificationsQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  from?: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetNotificationsQuery = { __typename?: 'Query', getNotifications?: { __typename?: 'NotificationList', total: number, unread: number, items?: Array<{ __typename?: 'Notification', type: NotificationType, reference_id?: string | null, notification_status?: NotificationStatus | null, startDate?: any | null, cpd_update_details?: { __typename?: 'CpdUpdateDetails', title?: string | null, description?: string | null, start_date?: string | null, end_date?: string | null, url?: string | null } | null } | null> | null } | null };

export type GetPdpDetailsQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
}>;


export type GetPdpDetailsQuery = { __typename?: 'Query', getPdpDetails?: { __typename?: 'PdpDetail', pdp_year?: string | null, scope_of_practice?: string | null, goals?: string | null, general_comments?: string | null, quick_log_reflection_comments?: string | null, annual_review_and_reflection?: string | null, pdp_status?: PdpStatus | null, commented_activities?: Array<string | null> | null, cpd_activity_key?: string | null, pdp_type?: PdpType | null, exempt?: boolean | null, current_situation?: string | null, reflection_culturally_save_practice?: string | null, reflection_health_inequities?: string | null, reflection_professionalism_ethical_practice?: string | null, hours_spent?: number | null, cpdActivityPlans?: Array<{ __typename?: 'CpdActivityPlan', type?: string | null, tags?: Array<string | null> | null, hours?: number | null } | null> | null, attachments?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null };

export type GetPdpStatusQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
}>;


export type GetPdpStatusQuery = { __typename?: 'Query', getPdpDetails?: { __typename?: 'PdpDetail', pdp_status?: PdpStatus | null, pdp_type?: PdpType | null } | null };

export type GetReferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferencesQuery = { __typename?: 'Query', activity_title?: { __typename?: 'ItemsResultOfLookup', items?: Array<{ __typename?: 'Lookup', lookup_type?: string | null, key?: string | null, value?: string | null } | null> | null } | null, activity_type?: { __typename?: 'ItemsResultOfLookup', items?: Array<{ __typename?: 'Lookup', lookup_type?: string | null, key?: string | null, value?: string | null } | null> | null } | null, specific_req_label?: { __typename?: 'ItemsResultOfLookup', items?: Array<{ __typename?: 'Lookup', lookup_type?: string | null, key?: string | null, value?: string | null } | null> | null } | null };

export type GetReferencesPdpQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferencesPdpQuery = { __typename?: 'Query', pdp_ea_list?: { __typename?: 'ItemsResultOfLookup', items?: Array<{ __typename?: 'Lookup', lookup_type?: string | null, key?: string | null, value?: string | null } | null> | null } | null, pdp_rp_list?: { __typename?: 'ItemsResultOfLookup', items?: Array<{ __typename?: 'Lookup', lookup_type?: string | null, key?: string | null, value?: string | null } | null> | null } | null, pdp_mo_list?: { __typename?: 'ItemsResultOfLookup', items?: Array<{ __typename?: 'Lookup', lookup_type?: string | null, key?: string | null, value?: string | null } | null> | null } | null };

export type GetSearchActivityQueryVariables = Exact<{
  request?: InputMaybe<SearchActivityInput>;
}>;


export type GetSearchActivityQuery = { __typename?: 'Query', searchActivity?: { __typename?: 'SearchActivityResult', total: any, items?: Array<{ __typename?: 'SearchActivityItem', score: number, activity?: { __typename?: 'CpdActivityContent', id?: string | null, hoursGroup?: string | null, tags?: Array<string | null> | null, hours: number, sessionSeriesStartDate?: any | null, sessionSeriesEndDate?: any | null, title?: string | null, providerName?: string | null, targetAudience?: string | null, deliveryMode?: string | null, providerType?: string | null, programLevels?: Array<string | null> | null, requirements?: Array<{ __typename?: 'CpdActivityRequirement', name?: string | null, hours: number } | null> | null, sessions?: Array<{ __typename?: 'CpdActivitySession', state?: string | null } | null> | null } | null } | null> | null, facets?: Array<{ __typename?: 'Facet', name?: string | null, values?: Array<{ __typename?: 'FacetValue', value?: string | null, count: any } | null> | null } | null> | null } | null };

export type GetSearchActivityByIdQueryVariables = Exact<{
  activityid: Scalars['String'];
}>;


export type GetSearchActivityByIdQuery = { __typename?: 'Query', searchActivity?: { __typename?: 'SearchActivityResult', items?: Array<{ __typename?: 'SearchActivityItem', activity?: { __typename?: 'CpdActivityContent', id?: string | null, hoursGroup?: string | null, tags?: Array<string | null> | null, hours: number, ruralCpdYesNo?: string | null, description?: string | null, sessionSeriesStartDate?: any | null, sessionSeriesEndDate?: any | null, title?: string | null, providerName?: string | null, targetAudience?: string | null, eligibleForGrantsYesNo?: string | null, hasAnaestheticGrant: boolean, hasEmergencyMedicineGrant: boolean, hasObstetricGrant: boolean, hasSurgeryGrant: boolean, relevance?: string | null, learningOutcomes?: Array<string | null> | null, gpDomains?: Array<string | null> | null, specificRequirements?: Array<string | null> | null, programLevels?: Array<string | null> | null, deliveryMode?: string | null, curriculumContextualUnits?: Array<{ __typename?: 'CpdActivityCurriculumContextualUnit', name?: string | null } | null> | null, requirements?: Array<{ __typename?: 'CpdActivityRequirement', name?: string | null, hours: number } | null> | null, sessions?: Array<{ __typename?: 'CpdActivitySession', eventId: number, state?: string | null, fromDateTime: any, toDateTime: any, venue?: string | null, postcode?: string | null } | null> | null, contact?: { __typename?: 'CpdActivityContact', firstName?: string | null, lastName?: string | null, phone?: string | null, email?: string | null } | null } | null } | null> | null } | null };

export type GetSearchActivitySuggestionsQueryVariables = Exact<{
  text?: InputMaybe<Scalars['String']>;
}>;


export type GetSearchActivitySuggestionsQuery = { __typename?: 'Query', searchActivitySuggestions?: Array<string | null> | null };

export type SearchBookmarksQueryVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  triennium?: InputMaybe<Scalars['String']>;
  limit?: Scalars['Int'];
}>;


export type SearchBookmarksQuery = { __typename?: 'Query', searchBookmarks?: { __typename?: 'ItemsResultOfBookmark', items?: Array<{ __typename?: 'Bookmark', sortkey?: string | null, internal_id?: string | null, partitionkey?: string | null, bookmarkkey?: string | null } | null> | null } | null };

export type GetSearchContentQueryVariables = Exact<{
  memberid: Scalars['String'];
  type: Scalars['String'];
  searchterm: Scalars['String'];
  limit: Scalars['Int'];
  from: Scalars['Int'];
  searchfields?: InputMaybe<Array<InputMaybe<SearchFieldInput>> | InputMaybe<SearchFieldInput>>;
  sessionstate: Scalars['String'];
  sessiondayofweek?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetSearchContentQuery = { __typename?: 'Query', searchContent?: { __typename?: 'ContentSearchResult', total: any, items?: Array<{ __typename?: 'ContentSearchItem', content?: { __typename?: 'CpdContent', sortkey?: string | null, title?: Array<string | null> | null, session_series_start_date?: string | null, session_series_end_date?: string | null, target_audience?: string | null, contact?: { __typename?: 'Contact', phone?: string | null, email?: string | null } | null } | null } | null> | null } | null };

export type SaveBookmarkMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  internal_type?: InputMaybe<Scalars['String']>;
  internal_id?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
}>;


export type SaveBookmarkMutation = { __typename?: 'Mutation', saveBookmark?: { __typename?: 'Bookmark', memberid?: string | null, internal_id?: string | null, internal_type?: string | null, title?: string | null } | null };

export type SaveHighlevelRequirementMutationVariables = Exact<{
  memberId?: InputMaybe<Scalars['String']>;
  hlrDetails?: InputMaybe<HlrActivityTypeDateRangeInput>;
  cpdTypes?: InputMaybe<Array<InputMaybe<CpdTypeInput>> | InputMaybe<CpdTypeInput>>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>> | InputMaybe<AttachmentInput>>;
}>;


export type SaveHighlevelRequirementMutation = { __typename?: 'Mutation', saveHighlevelRequirement?: { __typename?: 'HighLevelRequirementDetail', title?: string | null } | null };

export type SaveLearningInterestsMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  learning_interests?: InputMaybe<Array<InputMaybe<LearningInterestInput>> | InputMaybe<LearningInterestInput>>;
}>;


export type SaveLearningInterestsMutation = { __typename?: 'Mutation', saveLearningInterests?: { __typename?: 'ItemsResultOfLearningInterest', total: number, items?: Array<{ __typename?: 'LearningInterest', learning_interest_id?: string | null, learning_interest?: string | null, status: boolean } | null> | null } | null };

export type SaveMemberAttachmentsMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  attachmentKey?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>> | InputMaybe<AttachmentInput>>;
}>;


export type SaveMemberAttachmentsMutation = { __typename?: 'Mutation', saveMemberAttachments?: { __typename: 'MemberAttachment' } | null };

export type SaveNotificationPreferencesMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  notification_preferences?: InputMaybe<PreferenceInput>;
}>;


export type SaveNotificationPreferencesMutation = { __typename?: 'Mutation', saveNotificationPreferences?: { __typename?: 'Preference', cpd_updates: boolean, session_reminders?: { __typename?: 'SessionReminder', enabled: boolean, remind_before_days: number } | null } | null };

export type SavePdpDetailsMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
  pdp_details?: InputMaybe<PdpDetailInput>;
}>;


export type SavePdpDetailsMutation = { __typename?: 'Mutation', savePdpDetails?: { __typename?: 'PdpDetail', scope_of_practice?: string | null, goals?: string | null, general_comments?: string | null, quick_log_reflection_comments?: string | null, annual_review_and_reflection?: string | null, pdp_status?: PdpStatus | null, commented_activities?: Array<string | null> | null, cpd_activity_key?: string | null, pdp_type?: PdpType | null, exempt?: boolean | null, current_situation?: string | null, reflection_culturally_save_practice?: string | null, hours_spent?: number | null, cpdActivityPlans?: Array<{ __typename?: 'CpdActivityPlan', type?: string | null, tags?: Array<string | null> | null, hours?: number | null } | null> | null, attachments?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null };

export type SaveQuickLogActivityMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  activitykey?: InputMaybe<Scalars['String']>;
  hours: Scalars['Float'];
  date?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  cpd_types?: InputMaybe<Array<InputMaybe<CpdTypeInput>> | InputMaybe<CpdTypeInput>>;
  program_levels?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  description?: InputMaybe<Scalars['String']>;
  attachments?: InputMaybe<Array<InputMaybe<AttachmentInput>> | InputMaybe<AttachmentInput>>;
}>;


export type SaveQuickLogActivityMutation = { __typename?: 'Mutation', saveQuickLogActivity?: { __typename: 'Activity', title?: string | null, activitykey?: string | null } | null };

export type SubmitPdpMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  pdp_year?: InputMaybe<Scalars['String']>;
}>;


export type SubmitPdpMutation = { __typename?: 'Mutation', submitPdp?: { __typename?: 'PdpDetail', scope_of_practice?: string | null, goals?: string | null, general_comments?: string | null, quick_log_reflection_comments?: string | null, annual_review_and_reflection?: string | null, pdp_status?: PdpStatus | null, commented_activities?: Array<string | null> | null, cpd_activity_key?: string | null, pdp_type?: PdpType | null, exempt?: boolean | null, current_situation?: string | null, reflection_culturally_save_practice?: string | null, hours_spent?: number | null, cpdActivityPlans?: Array<{ __typename?: 'CpdActivityPlan', type?: string | null, tags?: Array<string | null> | null, hours?: number | null } | null> | null, attachments?: Array<{ __typename?: 'Attachment', attachment_name?: string | null, attachment_type: AttType, retrieval_key?: string | null, url?: string | null } | null> | null } | null };

export type UpdateNotificationsMutationVariables = Exact<{
  memberid?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Array<InputMaybe<SubscriptionInput>> | InputMaybe<SubscriptionInput>>;
}>;


export type UpdateNotificationsMutation = { __typename?: 'Mutation', updateNotifications?: { __typename?: 'NotificationList', total: number, unread: number, items?: Array<{ __typename?: 'Notification', type: NotificationType, reference_id?: string | null, notification_status?: NotificationStatus | null, startDate?: any | null, cpd_update_details?: { __typename?: 'CpdUpdateDetails', title?: string | null, description?: string | null, start_date?: string | null, end_date?: string | null, url?: string | null } | null } | null> | null } | null };


export const GetMemberCpdSummaryDocument = gql`
    query getMemberCpdSummary($memberid: String) {
  getMember(memberid: $memberid) {
    cpd_summary {
      accredited
      basic_live_skills
      total
      total_hours
      triennium
      type_of_cpd_split {
        hours
        type
      }
    }
  }
}
    `;

/**
 * __useGetMemberCpdSummaryQuery__
 *
 * To run a query within a React component, call `useGetMemberCpdSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberCpdSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberCpdSummaryQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *   },
 * });
 */
export function useGetMemberCpdSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberCpdSummaryQuery, GetMemberCpdSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberCpdSummaryQuery, GetMemberCpdSummaryQueryVariables>(GetMemberCpdSummaryDocument, options);
      }
export function useGetMemberCpdSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberCpdSummaryQuery, GetMemberCpdSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberCpdSummaryQuery, GetMemberCpdSummaryQueryVariables>(GetMemberCpdSummaryDocument, options);
        }
export type GetMemberCpdSummaryQueryHookResult = ReturnType<typeof useGetMemberCpdSummaryQuery>;
export type GetMemberCpdSummaryLazyQueryHookResult = ReturnType<typeof useGetMemberCpdSummaryLazyQuery>;
export type GetMemberCpdSummaryQueryResult = Apollo.QueryResult<GetMemberCpdSummaryQuery, GetMemberCpdSummaryQueryVariables>;
export const AddHighlevelRequirementDocument = gql`
    mutation addHighlevelRequirement($memberid: String, $asAtDate: String, $hlrdetails: HLRActivityTypeDateRangeInput) {
  addHighlevelRequirement(
    memberid: $memberid
    asAtDate: $asAtDate
    hlrdetails: $hlrdetails
  ) {
    activity_type_id
    activity_type
    title
    start_date
    end_date
  }
}
    `;
export type AddHighlevelRequirementMutationFn = Apollo.MutationFunction<AddHighlevelRequirementMutation, AddHighlevelRequirementMutationVariables>;

/**
 * __useAddHighlevelRequirementMutation__
 *
 * To run a mutation, you first call `useAddHighlevelRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHighlevelRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHighlevelRequirementMutation, { data, loading, error }] = useAddHighlevelRequirementMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      asAtDate: // value for 'asAtDate'
 *      hlrdetails: // value for 'hlrdetails'
 *   },
 * });
 */
export function useAddHighlevelRequirementMutation(baseOptions?: Apollo.MutationHookOptions<AddHighlevelRequirementMutation, AddHighlevelRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddHighlevelRequirementMutation, AddHighlevelRequirementMutationVariables>(AddHighlevelRequirementDocument, options);
      }
export type AddHighlevelRequirementMutationHookResult = ReturnType<typeof useAddHighlevelRequirementMutation>;
export type AddHighlevelRequirementMutationResult = Apollo.MutationResult<AddHighlevelRequirementMutation>;
export type AddHighlevelRequirementMutationOptions = Apollo.BaseMutationOptions<AddHighlevelRequirementMutation, AddHighlevelRequirementMutationVariables>;
export const AddNotificationDocument = gql`
    mutation addNotification($memberid: String, $notification: SessionNotificationSubscriptionInput) {
  addNotification(memberid: $memberid, notification: $notification) {
    total
    unread
  }
}
    `;
export type AddNotificationMutationFn = Apollo.MutationFunction<AddNotificationMutation, AddNotificationMutationVariables>;

/**
 * __useAddNotificationMutation__
 *
 * To run a mutation, you first call `useAddNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNotificationMutation, { data, loading, error }] = useAddNotificationMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      notification: // value for 'notification'
 *   },
 * });
 */
export function useAddNotificationMutation(baseOptions?: Apollo.MutationHookOptions<AddNotificationMutation, AddNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddNotificationMutation, AddNotificationMutationVariables>(AddNotificationDocument, options);
      }
export type AddNotificationMutationHookResult = ReturnType<typeof useAddNotificationMutation>;
export type AddNotificationMutationResult = Apollo.MutationResult<AddNotificationMutation>;
export type AddNotificationMutationOptions = Apollo.BaseMutationOptions<AddNotificationMutation, AddNotificationMutationVariables>;
export const BackdateActivityDocument = gql`
    mutation backdateActivity($memberId: String, $activitykey: String) {
  backdateActivity(memberId: $memberId, activitykey: $activitykey) {
    cpd_id
    is_backdated
  }
}
    `;
export type BackdateActivityMutationFn = Apollo.MutationFunction<BackdateActivityMutation, BackdateActivityMutationVariables>;

/**
 * __useBackdateActivityMutation__
 *
 * To run a mutation, you first call `useBackdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backdateActivityMutation, { data, loading, error }] = useBackdateActivityMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      activitykey: // value for 'activitykey'
 *   },
 * });
 */
export function useBackdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<BackdateActivityMutation, BackdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackdateActivityMutation, BackdateActivityMutationVariables>(BackdateActivityDocument, options);
      }
export type BackdateActivityMutationHookResult = ReturnType<typeof useBackdateActivityMutation>;
export type BackdateActivityMutationResult = Apollo.MutationResult<BackdateActivityMutation>;
export type BackdateActivityMutationOptions = Apollo.BaseMutationOptions<BackdateActivityMutation, BackdateActivityMutationVariables>;
export const DeleteAllBookmarksDocument = gql`
    mutation deleteAllBookmarks($memberid: String, $triennium: String) {
  deleteAllBookmarks(memberid: $memberid, triennium: $triennium) {
    total
  }
}
    `;
export type DeleteAllBookmarksMutationFn = Apollo.MutationFunction<DeleteAllBookmarksMutation, DeleteAllBookmarksMutationVariables>;

/**
 * __useDeleteAllBookmarksMutation__
 *
 * To run a mutation, you first call `useDeleteAllBookmarksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllBookmarksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllBookmarksMutation, { data, loading, error }] = useDeleteAllBookmarksMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      triennium: // value for 'triennium'
 *   },
 * });
 */
export function useDeleteAllBookmarksMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllBookmarksMutation, DeleteAllBookmarksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllBookmarksMutation, DeleteAllBookmarksMutationVariables>(DeleteAllBookmarksDocument, options);
      }
export type DeleteAllBookmarksMutationHookResult = ReturnType<typeof useDeleteAllBookmarksMutation>;
export type DeleteAllBookmarksMutationResult = Apollo.MutationResult<DeleteAllBookmarksMutation>;
export type DeleteAllBookmarksMutationOptions = Apollo.BaseMutationOptions<DeleteAllBookmarksMutation, DeleteAllBookmarksMutationVariables>;
export const DeleteBookmarkDocument = gql`
    mutation deleteBookmark($memberid: String, $bookmarkkey: String) {
  deleteBookmark(memberid: $memberid, bookmarkkey: $bookmarkkey) {
    memberid
    internal_id
    internal_type
    title
  }
}
    `;
export type DeleteBookmarkMutationFn = Apollo.MutationFunction<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>;

/**
 * __useDeleteBookmarkMutation__
 *
 * To run a mutation, you first call `useDeleteBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBookmarkMutation, { data, loading, error }] = useDeleteBookmarkMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      bookmarkkey: // value for 'bookmarkkey'
 *   },
 * });
 */
export function useDeleteBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>(DeleteBookmarkDocument, options);
      }
export type DeleteBookmarkMutationHookResult = ReturnType<typeof useDeleteBookmarkMutation>;
export type DeleteBookmarkMutationResult = Apollo.MutationResult<DeleteBookmarkMutation>;
export type DeleteBookmarkMutationOptions = Apollo.BaseMutationOptions<DeleteBookmarkMutation, DeleteBookmarkMutationVariables>;
export const DeleteHlrActivityDocument = gql`
    mutation deleteHlrActivity($memberid: String, $activitykey: String) {
  deleteHlrActivity(memberId: $memberid, activitykey: $activitykey) {
    title
    sortkey
  }
}
    `;
export type DeleteHlrActivityMutationFn = Apollo.MutationFunction<DeleteHlrActivityMutation, DeleteHlrActivityMutationVariables>;

/**
 * __useDeleteHlrActivityMutation__
 *
 * To run a mutation, you first call `useDeleteHlrActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHlrActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHlrActivityMutation, { data, loading, error }] = useDeleteHlrActivityMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      activitykey: // value for 'activitykey'
 *   },
 * });
 */
export function useDeleteHlrActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHlrActivityMutation, DeleteHlrActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHlrActivityMutation, DeleteHlrActivityMutationVariables>(DeleteHlrActivityDocument, options);
      }
export type DeleteHlrActivityMutationHookResult = ReturnType<typeof useDeleteHlrActivityMutation>;
export type DeleteHlrActivityMutationResult = Apollo.MutationResult<DeleteHlrActivityMutation>;
export type DeleteHlrActivityMutationOptions = Apollo.BaseMutationOptions<DeleteHlrActivityMutation, DeleteHlrActivityMutationVariables>;
export const DeleteMemberAttachmentsDocument = gql`
    mutation DeleteMemberAttachments($memberid: String, $activitykey: String, $attachments: [AttachmentInput]) {
  deleteMemberAttachments(
    memberid: $memberid
    attachmentKey: $activitykey
    attachments: $attachments
  ) {
    __typename
  }
}
    `;
export type DeleteMemberAttachmentsMutationFn = Apollo.MutationFunction<DeleteMemberAttachmentsMutation, DeleteMemberAttachmentsMutationVariables>;

/**
 * __useDeleteMemberAttachmentsMutation__
 *
 * To run a mutation, you first call `useDeleteMemberAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMemberAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMemberAttachmentsMutation, { data, loading, error }] = useDeleteMemberAttachmentsMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      activitykey: // value for 'activitykey'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useDeleteMemberAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMemberAttachmentsMutation, DeleteMemberAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMemberAttachmentsMutation, DeleteMemberAttachmentsMutationVariables>(DeleteMemberAttachmentsDocument, options);
      }
export type DeleteMemberAttachmentsMutationHookResult = ReturnType<typeof useDeleteMemberAttachmentsMutation>;
export type DeleteMemberAttachmentsMutationResult = Apollo.MutationResult<DeleteMemberAttachmentsMutation>;
export type DeleteMemberAttachmentsMutationOptions = Apollo.BaseMutationOptions<DeleteMemberAttachmentsMutation, DeleteMemberAttachmentsMutationVariables>;
export const DeleteNotificationsDocument = gql`
    mutation deleteNotifications($memberid: String, $notifications: [SubscriptionInput], $deleteAll: Boolean! = false) {
  deleteNotifications(
    memberid: $memberid
    notifications: $notifications
    deleteAll: $deleteAll
  ) {
    total
    unread
    items {
      type
      reference_id
      notification_status
      startDate
      cpd_update_details {
        title
        description
        start_date
        end_date
        url
      }
    }
  }
}
    `;
export type DeleteNotificationsMutationFn = Apollo.MutationFunction<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>;

/**
 * __useDeleteNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationsMutation, { data, loading, error }] = useDeleteNotificationsMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      notifications: // value for 'notifications'
 *      deleteAll: // value for 'deleteAll'
 *   },
 * });
 */
export function useDeleteNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>(DeleteNotificationsDocument, options);
      }
export type DeleteNotificationsMutationHookResult = ReturnType<typeof useDeleteNotificationsMutation>;
export type DeleteNotificationsMutationResult = Apollo.MutationResult<DeleteNotificationsMutation>;
export type DeleteNotificationsMutationOptions = Apollo.BaseMutationOptions<DeleteNotificationsMutation, DeleteNotificationsMutationVariables>;
export const DeleteQuickLogActivityDocument = gql`
    mutation DeleteQuickLogActivity($memberid: String, $activitykey: String) {
  deleteQuickLogActivity(memberid: $memberid, activitykey: $activitykey) {
    activitykey
  }
}
    `;
export type DeleteQuickLogActivityMutationFn = Apollo.MutationFunction<DeleteQuickLogActivityMutation, DeleteQuickLogActivityMutationVariables>;

/**
 * __useDeleteQuickLogActivityMutation__
 *
 * To run a mutation, you first call `useDeleteQuickLogActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuickLogActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuickLogActivityMutation, { data, loading, error }] = useDeleteQuickLogActivityMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      activitykey: // value for 'activitykey'
 *   },
 * });
 */
export function useDeleteQuickLogActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuickLogActivityMutation, DeleteQuickLogActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuickLogActivityMutation, DeleteQuickLogActivityMutationVariables>(DeleteQuickLogActivityDocument, options);
      }
export type DeleteQuickLogActivityMutationHookResult = ReturnType<typeof useDeleteQuickLogActivityMutation>;
export type DeleteQuickLogActivityMutationResult = Apollo.MutationResult<DeleteQuickLogActivityMutation>;
export type DeleteQuickLogActivityMutationOptions = Apollo.BaseMutationOptions<DeleteQuickLogActivityMutation, DeleteQuickLogActivityMutationVariables>;
export const GetAttendanceCertificateUrlDocument = gql`
    query getAttendanceCertificateUrl($memberid: String, $activitykey: String) {
  getAttendanceCertificateUrl(memberid: $memberid, activitykey: $activitykey) {
    items {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
    __typename
  }
}
    `;

/**
 * __useGetAttendanceCertificateUrlQuery__
 *
 * To run a query within a React component, call `useGetAttendanceCertificateUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttendanceCertificateUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttendanceCertificateUrlQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      activitykey: // value for 'activitykey'
 *   },
 * });
 */
export function useGetAttendanceCertificateUrlQuery(baseOptions?: Apollo.QueryHookOptions<GetAttendanceCertificateUrlQuery, GetAttendanceCertificateUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAttendanceCertificateUrlQuery, GetAttendanceCertificateUrlQueryVariables>(GetAttendanceCertificateUrlDocument, options);
      }
export function useGetAttendanceCertificateUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttendanceCertificateUrlQuery, GetAttendanceCertificateUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAttendanceCertificateUrlQuery, GetAttendanceCertificateUrlQueryVariables>(GetAttendanceCertificateUrlDocument, options);
        }
export type GetAttendanceCertificateUrlQueryHookResult = ReturnType<typeof useGetAttendanceCertificateUrlQuery>;
export type GetAttendanceCertificateUrlLazyQueryHookResult = ReturnType<typeof useGetAttendanceCertificateUrlLazyQuery>;
export type GetAttendanceCertificateUrlQueryResult = Apollo.QueryResult<GetAttendanceCertificateUrlQuery, GetAttendanceCertificateUrlQueryVariables>;
export const GetBookmarksDocument = gql`
    query getBookmarks($memberid: String, $bookmarkkey: String, $triennium: String, $limit: Int! = 20) {
  getBookmarks(
    memberid: $memberid
    bookmarkkey: $bookmarkkey
    triennium: $triennium
    limit: $limit
  ) {
    items {
      sortkey
      internal_id
      partitionkey
      bookmarkkey
    }
  }
}
    `;

/**
 * __useGetBookmarksQuery__
 *
 * To run a query within a React component, call `useGetBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookmarksQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      bookmarkkey: // value for 'bookmarkkey'
 *      triennium: // value for 'triennium'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<GetBookmarksQuery, GetBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBookmarksQuery, GetBookmarksQueryVariables>(GetBookmarksDocument, options);
      }
export function useGetBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBookmarksQuery, GetBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBookmarksQuery, GetBookmarksQueryVariables>(GetBookmarksDocument, options);
        }
export type GetBookmarksQueryHookResult = ReturnType<typeof useGetBookmarksQuery>;
export type GetBookmarksLazyQueryHookResult = ReturnType<typeof useGetBookmarksLazyQuery>;
export type GetBookmarksQueryResult = Apollo.QueryResult<GetBookmarksQuery, GetBookmarksQueryVariables>;
export const GetContentByIdDocument = gql`
    query getContentById($id: String!, $entitytype: String!) {
  getContentById(id: $id, entitytype: $entitytype) {
    title
    description
    hours
    session_series_start_date
    session_series_end_date
    target_audience
    contact {
      phone
      email
    }
  }
}
    `;

/**
 * __useGetContentByIdQuery__
 *
 * To run a query within a React component, call `useGetContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      entitytype: // value for 'entitytype'
 *   },
 * });
 */
export function useGetContentByIdQuery(baseOptions: Apollo.QueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
      }
export function useGetContentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
        }
export type GetContentByIdQueryHookResult = ReturnType<typeof useGetContentByIdQuery>;
export type GetContentByIdLazyQueryHookResult = ReturnType<typeof useGetContentByIdLazyQuery>;
export type GetContentByIdQueryResult = Apollo.QueryResult<GetContentByIdQuery, GetContentByIdQueryVariables>;
export const GetCpdStatementDocument = gql`
    query getCpdStatement($memberid: String, $asAtDate: String) {
  getCpdStatement(memberid: $memberid, asAtDate: $asAtDate) {
    url
    attachment_name
  }
}
    `;

/**
 * __useGetCpdStatementQuery__
 *
 * To run a query within a React component, call `useGetCpdStatementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCpdStatementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCpdStatementQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      asAtDate: // value for 'asAtDate'
 *   },
 * });
 */
export function useGetCpdStatementQuery(baseOptions?: Apollo.QueryHookOptions<GetCpdStatementQuery, GetCpdStatementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCpdStatementQuery, GetCpdStatementQueryVariables>(GetCpdStatementDocument, options);
      }
export function useGetCpdStatementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCpdStatementQuery, GetCpdStatementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCpdStatementQuery, GetCpdStatementQueryVariables>(GetCpdStatementDocument, options);
        }
export type GetCpdStatementQueryHookResult = ReturnType<typeof useGetCpdStatementQuery>;
export type GetCpdStatementLazyQueryHookResult = ReturnType<typeof useGetCpdStatementLazyQuery>;
export type GetCpdStatementQueryResult = Apollo.QueryResult<GetCpdStatementQuery, GetCpdStatementQueryVariables>;
export const GetRequirementsSummaryDocument = gql`
    query getRequirementsSummary($memberid: String, $date: String) {
  getRequirementsSummary(memberid: $memberid, date: $date) {
    cycle
    memberid
    date
    total_category_group_requirements
    total_completed_category_group_requirements
    requirements {
      requirement_code
      requirement_from
      requirement_to
      description
      hours
      completed
      minimum
      category_code_totals {
        hours
        type
      }
      category_group_requirements {
        category_group_code
        description
        minimum
        maximum
        hours
        completed
        program_levels
        category_requirements {
          category_code
          description
          minimum
          maximum
          hours
          completed
        }
      }
    }
    __typename
  }
}
    `;

/**
 * __useGetRequirementsSummaryQuery__
 *
 * To run a query within a React component, call `useGetRequirementsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequirementsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequirementsSummaryQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetRequirementsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetRequirementsSummaryQuery, GetRequirementsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequirementsSummaryQuery, GetRequirementsSummaryQueryVariables>(GetRequirementsSummaryDocument, options);
      }
export function useGetRequirementsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequirementsSummaryQuery, GetRequirementsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequirementsSummaryQuery, GetRequirementsSummaryQueryVariables>(GetRequirementsSummaryDocument, options);
        }
export type GetRequirementsSummaryQueryHookResult = ReturnType<typeof useGetRequirementsSummaryQuery>;
export type GetRequirementsSummaryLazyQueryHookResult = ReturnType<typeof useGetRequirementsSummaryLazyQuery>;
export type GetRequirementsSummaryQueryResult = Apollo.QueryResult<GetRequirementsSummaryQuery, GetRequirementsSummaryQueryVariables>;
export const GetCycleDocument = gql`
    query getCycle($cycleName: String) {
  getCycle(cycleName: $cycleName) {
    name
    start_date
    end_date
    close_date
  }
}
    `;

/**
 * __useGetCycleQuery__
 *
 * To run a query within a React component, call `useGetCycleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCycleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCycleQuery({
 *   variables: {
 *      cycleName: // value for 'cycleName'
 *   },
 * });
 */
export function useGetCycleQuery(baseOptions?: Apollo.QueryHookOptions<GetCycleQuery, GetCycleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCycleQuery, GetCycleQueryVariables>(GetCycleDocument, options);
      }
export function useGetCycleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCycleQuery, GetCycleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCycleQuery, GetCycleQueryVariables>(GetCycleDocument, options);
        }
export type GetCycleQueryHookResult = ReturnType<typeof useGetCycleQuery>;
export type GetCycleLazyQueryHookResult = ReturnType<typeof useGetCycleLazyQuery>;
export type GetCycleQueryResult = Apollo.QueryResult<GetCycleQuery, GetCycleQueryVariables>;
export const GetGeneratedUrlToUploadMemberAttachmentsDocument = gql`
    query getGeneratedUrlToUploadMemberAttachments($memberid: String, $attachments: [AttachmentInput]) {
  getGeneratedUrlToUploadMemberAttachments(
    memberid: $memberid
    attachments: $attachments
  ) {
    attachment_name
    attachment_type
    retrieval_key
    url
    __typename
  }
}
    `;

/**
 * __useGetGeneratedUrlToUploadMemberAttachmentsQuery__
 *
 * To run a query within a React component, call `useGetGeneratedUrlToUploadMemberAttachmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedUrlToUploadMemberAttachmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedUrlToUploadMemberAttachmentsQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useGetGeneratedUrlToUploadMemberAttachmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetGeneratedUrlToUploadMemberAttachmentsQuery, GetGeneratedUrlToUploadMemberAttachmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGeneratedUrlToUploadMemberAttachmentsQuery, GetGeneratedUrlToUploadMemberAttachmentsQueryVariables>(GetGeneratedUrlToUploadMemberAttachmentsDocument, options);
      }
export function useGetGeneratedUrlToUploadMemberAttachmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedUrlToUploadMemberAttachmentsQuery, GetGeneratedUrlToUploadMemberAttachmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGeneratedUrlToUploadMemberAttachmentsQuery, GetGeneratedUrlToUploadMemberAttachmentsQueryVariables>(GetGeneratedUrlToUploadMemberAttachmentsDocument, options);
        }
export type GetGeneratedUrlToUploadMemberAttachmentsQueryHookResult = ReturnType<typeof useGetGeneratedUrlToUploadMemberAttachmentsQuery>;
export type GetGeneratedUrlToUploadMemberAttachmentsLazyQueryHookResult = ReturnType<typeof useGetGeneratedUrlToUploadMemberAttachmentsLazyQuery>;
export type GetGeneratedUrlToUploadMemberAttachmentsQueryResult = Apollo.QueryResult<GetGeneratedUrlToUploadMemberAttachmentsQuery, GetGeneratedUrlToUploadMemberAttachmentsQueryVariables>;
export const GetLearningInterestsDocument = gql`
    query getLearningInterests($memberid: String) {
  getLearningInterests(memberid: $memberid) {
    items {
      learning_interest_id
      learning_interest
      status
    }
    total
  }
}
    `;

/**
 * __useGetLearningInterestsQuery__
 *
 * To run a query within a React component, call `useGetLearningInterestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningInterestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningInterestsQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *   },
 * });
 */
export function useGetLearningInterestsQuery(baseOptions?: Apollo.QueryHookOptions<GetLearningInterestsQuery, GetLearningInterestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLearningInterestsQuery, GetLearningInterestsQueryVariables>(GetLearningInterestsDocument, options);
      }
export function useGetLearningInterestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLearningInterestsQuery, GetLearningInterestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLearningInterestsQuery, GetLearningInterestsQueryVariables>(GetLearningInterestsDocument, options);
        }
export type GetLearningInterestsQueryHookResult = ReturnType<typeof useGetLearningInterestsQuery>;
export type GetLearningInterestsLazyQueryHookResult = ReturnType<typeof useGetLearningInterestsLazyQuery>;
export type GetLearningInterestsQueryResult = Apollo.QueryResult<GetLearningInterestsQuery, GetLearningInterestsQueryVariables>;
export const GetMemberDocument = gql`
    query getMember($memberid: String) {
  getMember(memberid: $memberid) {
    first_name
    last_name
    full_name
    memberid
    address {
      address_1
      address_2
      address_3
      address_type
      postcode
      state
      suburb
    }
  }
}
    `;

/**
 * __useGetMemberQuery__
 *
 * To run a query within a React component, call `useGetMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *   },
 * });
 */
export function useGetMemberQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
      }
export function useGetMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberQuery, GetMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberQuery, GetMemberQueryVariables>(GetMemberDocument, options);
        }
export type GetMemberQueryHookResult = ReturnType<typeof useGetMemberQuery>;
export type GetMemberLazyQueryHookResult = ReturnType<typeof useGetMemberLazyQuery>;
export type GetMemberQueryResult = Apollo.QueryResult<GetMemberQuery, GetMemberQueryVariables>;
export const GetMemberActivitiesDocument = gql`
    query getMemberActivities($memberid: String, $cycleName: String, $nextToken: String) {
  getMemberActivities(
    memberid: $memberid
    cycleName: $cycleName
    limit: 100
    nextToken: $nextToken
  ) {
    items {
      cpd_id
      activitykey
      activity_type
      history_record_type
      date
      cycle
      title
      type_of_cpd
      hours
      provider_name
      description
      is_racgp_content
      pdp_year
      cpd_types {
        hours
        type
      }
      type
      is_backdated
      exempt
      __typename
      req_eligibility
    }
    nextToken
  }
}
    `;

/**
 * __useGetMemberActivitiesQuery__
 *
 * To run a query within a React component, call `useGetMemberActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberActivitiesQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      cycleName: // value for 'cycleName'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetMemberActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>(GetMemberActivitiesDocument, options);
      }
export function useGetMemberActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>(GetMemberActivitiesDocument, options);
        }
export type GetMemberActivitiesQueryHookResult = ReturnType<typeof useGetMemberActivitiesQuery>;
export type GetMemberActivitiesLazyQueryHookResult = ReturnType<typeof useGetMemberActivitiesLazyQuery>;
export type GetMemberActivitiesQueryResult = Apollo.QueryResult<GetMemberActivitiesQuery, GetMemberActivitiesQueryVariables>;
export const GetMemberActivityByCpdIdDocument = gql`
    query getMemberActivityByCpdId($memberid: String, $cpdId: String) {
  getMemberActivityByCpdId(memberid: $memberid, cpdId: $cpdId) {
    activitykey
    hours
    date
    title
    cpd_id
    cpd_types {
      hours
      type
    }
    program_levels
    description
    attachments {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
  }
}
    `;

/**
 * __useGetMemberActivityByCpdIdQuery__
 *
 * To run a query within a React component, call `useGetMemberActivityByCpdIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberActivityByCpdIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberActivityByCpdIdQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      cpdId: // value for 'cpdId'
 *   },
 * });
 */
export function useGetMemberActivityByCpdIdQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberActivityByCpdIdQuery, GetMemberActivityByCpdIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberActivityByCpdIdQuery, GetMemberActivityByCpdIdQueryVariables>(GetMemberActivityByCpdIdDocument, options);
      }
export function useGetMemberActivityByCpdIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberActivityByCpdIdQuery, GetMemberActivityByCpdIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberActivityByCpdIdQuery, GetMemberActivityByCpdIdQueryVariables>(GetMemberActivityByCpdIdDocument, options);
        }
export type GetMemberActivityByCpdIdQueryHookResult = ReturnType<typeof useGetMemberActivityByCpdIdQuery>;
export type GetMemberActivityByCpdIdLazyQueryHookResult = ReturnType<typeof useGetMemberActivityByCpdIdLazyQuery>;
export type GetMemberActivityByCpdIdQueryResult = Apollo.QueryResult<GetMemberActivityByCpdIdQuery, GetMemberActivityByCpdIdQueryVariables>;
export const GetMemberActivityByKeyDocument = gql`
    query getMemberActivityByKey($memberid: String, $activitykey: String) {
  getMemberActivityByKey(memberid: $memberid, activitykey: $activitykey) {
    activitykey
    hours
    date
    title
    cpd_id
    cpd_types {
      hours
      type
    }
    description
    attachments {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
    program_levels
  }
}
    `;

/**
 * __useGetMemberActivityByKeyQuery__
 *
 * To run a query within a React component, call `useGetMemberActivityByKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberActivityByKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberActivityByKeyQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      activitykey: // value for 'activitykey'
 *   },
 * });
 */
export function useGetMemberActivityByKeyQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberActivityByKeyQuery, GetMemberActivityByKeyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberActivityByKeyQuery, GetMemberActivityByKeyQueryVariables>(GetMemberActivityByKeyDocument, options);
      }
export function useGetMemberActivityByKeyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberActivityByKeyQuery, GetMemberActivityByKeyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberActivityByKeyQuery, GetMemberActivityByKeyQueryVariables>(GetMemberActivityByKeyDocument, options);
        }
export type GetMemberActivityByKeyQueryHookResult = ReturnType<typeof useGetMemberActivityByKeyQuery>;
export type GetMemberActivityByKeyLazyQueryHookResult = ReturnType<typeof useGetMemberActivityByKeyLazyQuery>;
export type GetMemberActivityByKeyQueryResult = Apollo.QueryResult<GetMemberActivityByKeyQuery, GetMemberActivityByKeyQueryVariables>;
export const GetMemberAttachmentUrlsDocument = gql`
    query getMemberAttachmentUrls($memberid: String, $attachments: [AttachmentInput]) {
  getMemberAttachmentsUrls(memberid: $memberid, attachments: $attachments) {
    url
    retrieval_key
    attachment_type
    attachment_name
  }
}
    `;

/**
 * __useGetMemberAttachmentUrlsQuery__
 *
 * To run a query within a React component, call `useGetMemberAttachmentUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberAttachmentUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberAttachmentUrlsQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useGetMemberAttachmentUrlsQuery(baseOptions?: Apollo.QueryHookOptions<GetMemberAttachmentUrlsQuery, GetMemberAttachmentUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberAttachmentUrlsQuery, GetMemberAttachmentUrlsQueryVariables>(GetMemberAttachmentUrlsDocument, options);
      }
export function useGetMemberAttachmentUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberAttachmentUrlsQuery, GetMemberAttachmentUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberAttachmentUrlsQuery, GetMemberAttachmentUrlsQueryVariables>(GetMemberAttachmentUrlsDocument, options);
        }
export type GetMemberAttachmentUrlsQueryHookResult = ReturnType<typeof useGetMemberAttachmentUrlsQuery>;
export type GetMemberAttachmentUrlsLazyQueryHookResult = ReturnType<typeof useGetMemberAttachmentUrlsLazyQuery>;
export type GetMemberAttachmentUrlsQueryResult = Apollo.QueryResult<GetMemberAttachmentUrlsQuery, GetMemberAttachmentUrlsQueryVariables>;
export const GetMemberHlrSearchDocument = gql`
    query getMemberHlrSearch($memberId: String, $dateAsAt: String!) {
  getMemberHlrSearch(memberId: $memberId, dateAsAt: $dateAsAt) {
    items {
      activity_type_id
      activity_type_code
      title
      start_date
      end_date
      status
      activitykey
      activity_type_hlr_heading
      activity_type_hlr_specialist_college
      activity_type_hlr_specialist_college_phone
      activity_type_hlr_specialist_college_website
      activity_type_hlr_content
    }
  }
}
    `;

/**
 * __useGetMemberHlrSearchQuery__
 *
 * To run a query within a React component, call `useGetMemberHlrSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberHlrSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberHlrSearchQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      dateAsAt: // value for 'dateAsAt'
 *   },
 * });
 */
export function useGetMemberHlrSearchQuery(baseOptions: Apollo.QueryHookOptions<GetMemberHlrSearchQuery, GetMemberHlrSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberHlrSearchQuery, GetMemberHlrSearchQueryVariables>(GetMemberHlrSearchDocument, options);
      }
export function useGetMemberHlrSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberHlrSearchQuery, GetMemberHlrSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberHlrSearchQuery, GetMemberHlrSearchQueryVariables>(GetMemberHlrSearchDocument, options);
        }
export type GetMemberHlrSearchQueryHookResult = ReturnType<typeof useGetMemberHlrSearchQuery>;
export type GetMemberHlrSearchLazyQueryHookResult = ReturnType<typeof useGetMemberHlrSearchLazyQuery>;
export type GetMemberHlrSearchQueryResult = Apollo.QueryResult<GetMemberHlrSearchQuery, GetMemberHlrSearchQueryVariables>;
export const GetMemberHlrDetailsDocument = gql`
    query getMemberHlrDetails($memberId: String, $asAtDate: String!) {
  getMemberHlrDetails(memberId: $memberId, asAtDate: $asAtDate) {
    title
    description
    cpd_id
    activity_type_id
    activity_type
    start_date
    end_date
    hlr_status
    type_of_cpd
    cpd_types {
      hours
      type
    }
    hours
    cycle
    attachments {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
    category
    category_number
    date
    history_record_type
    triennium
    partitionkey
    sortkey
  }
}
    `;

/**
 * __useGetMemberHlrDetailsQuery__
 *
 * To run a query within a React component, call `useGetMemberHlrDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberHlrDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberHlrDetailsQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      asAtDate: // value for 'asAtDate'
 *   },
 * });
 */
export function useGetMemberHlrDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetMemberHlrDetailsQuery, GetMemberHlrDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberHlrDetailsQuery, GetMemberHlrDetailsQueryVariables>(GetMemberHlrDetailsDocument, options);
      }
export function useGetMemberHlrDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberHlrDetailsQuery, GetMemberHlrDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberHlrDetailsQuery, GetMemberHlrDetailsQueryVariables>(GetMemberHlrDetailsDocument, options);
        }
export type GetMemberHlrDetailsQueryHookResult = ReturnType<typeof useGetMemberHlrDetailsQuery>;
export type GetMemberHlrDetailsLazyQueryHookResult = ReturnType<typeof useGetMemberHlrDetailsLazyQuery>;
export type GetMemberHlrDetailsQueryResult = Apollo.QueryResult<GetMemberHlrDetailsQuery, GetMemberHlrDetailsQueryVariables>;
export const GetMemberPlrDetailDocument = gql`
    query getMemberPlrDetail($memberId: String, $asAtDate: String!) {
  getMemberPlrDetail(memberid: $memberId, asAtDate: $asAtDate) {
    plrCard_status
    plrItem {
      plr
    }
    start_date
    end_date
  }
}
    `;

/**
 * __useGetMemberPlrDetailQuery__
 *
 * To run a query within a React component, call `useGetMemberPlrDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMemberPlrDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMemberPlrDetailQuery({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      asAtDate: // value for 'asAtDate'
 *   },
 * });
 */
export function useGetMemberPlrDetailQuery(baseOptions: Apollo.QueryHookOptions<GetMemberPlrDetailQuery, GetMemberPlrDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMemberPlrDetailQuery, GetMemberPlrDetailQueryVariables>(GetMemberPlrDetailDocument, options);
      }
export function useGetMemberPlrDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMemberPlrDetailQuery, GetMemberPlrDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMemberPlrDetailQuery, GetMemberPlrDetailQueryVariables>(GetMemberPlrDetailDocument, options);
        }
export type GetMemberPlrDetailQueryHookResult = ReturnType<typeof useGetMemberPlrDetailQuery>;
export type GetMemberPlrDetailLazyQueryHookResult = ReturnType<typeof useGetMemberPlrDetailLazyQuery>;
export type GetMemberPlrDetailQueryResult = Apollo.QueryResult<GetMemberPlrDetailQuery, GetMemberPlrDetailQueryVariables>;
export const GetNotificationPreferencesDocument = gql`
    query getNotificationPreferences($memberid: String) {
  getNotificationPreferences(memberid: $memberid) {
    session_reminders {
      enabled
      remind_before_days
    }
    cpd_updates
  }
}
    `;

/**
 * __useGetNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationPreferencesQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *   },
 * });
 */
export function useGetNotificationPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>(GetNotificationPreferencesDocument, options);
      }
export function useGetNotificationPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>(GetNotificationPreferencesDocument, options);
        }
export type GetNotificationPreferencesQueryHookResult = ReturnType<typeof useGetNotificationPreferencesQuery>;
export type GetNotificationPreferencesLazyQueryHookResult = ReturnType<typeof useGetNotificationPreferencesLazyQuery>;
export type GetNotificationPreferencesQueryResult = Apollo.QueryResult<GetNotificationPreferencesQuery, GetNotificationPreferencesQueryVariables>;
export const GetNotificationSubscriptionsDocument = gql`
    query getNotificationSubscriptions($memberid: String) {
  getNotificationSubscriptions(memberid: $memberid) {
    total
    unread
    items {
      type
      reference_id
      notification_status
    }
  }
}
    `;

/**
 * __useGetNotificationSubscriptionsQuery__
 *
 * To run a query within a React component, call `useGetNotificationSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationSubscriptionsQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *   },
 * });
 */
export function useGetNotificationSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationSubscriptionsQuery, GetNotificationSubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationSubscriptionsQuery, GetNotificationSubscriptionsQueryVariables>(GetNotificationSubscriptionsDocument, options);
      }
export function useGetNotificationSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationSubscriptionsQuery, GetNotificationSubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationSubscriptionsQuery, GetNotificationSubscriptionsQueryVariables>(GetNotificationSubscriptionsDocument, options);
        }
export type GetNotificationSubscriptionsQueryHookResult = ReturnType<typeof useGetNotificationSubscriptionsQuery>;
export type GetNotificationSubscriptionsLazyQueryHookResult = ReturnType<typeof useGetNotificationSubscriptionsLazyQuery>;
export type GetNotificationSubscriptionsQueryResult = Apollo.QueryResult<GetNotificationSubscriptionsQuery, GetNotificationSubscriptionsQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($memberid: String, $from: Int! = 1, $limit: Int! = 20) {
  getNotifications(memberid: $memberid, from: $from, limit: $limit) {
    total
    unread
    items {
      type
      reference_id
      notification_status
      startDate
      cpd_update_details {
        title
        description
        start_date
        end_date
        url
      }
    }
  }
}
    `;

/**
 * __useGetNotificationsQuery__
 *
 * To run a query within a React component, call `useGetNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      from: // value for 'from'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetPdpDetailsDocument = gql`
    query getPdpDetails($memberid: String, $pdp_year: String) {
  getPdpDetails(memberid: $memberid, pdp_year: $pdp_year) {
    pdp_year
    scope_of_practice
    goals
    general_comments
    quick_log_reflection_comments
    annual_review_and_reflection
    pdp_status
    commented_activities
    cpd_activity_key
    cpdActivityPlans {
      type
      tags
      hours
    }
    attachments {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
    pdp_type
    exempt
    current_situation
    reflection_culturally_save_practice
    reflection_health_inequities
    reflection_professionalism_ethical_practice
    hours_spent
  }
}
    `;

/**
 * __useGetPdpDetailsQuery__
 *
 * To run a query within a React component, call `useGetPdpDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdpDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdpDetailsQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      pdp_year: // value for 'pdp_year'
 *   },
 * });
 */
export function useGetPdpDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetPdpDetailsQuery, GetPdpDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPdpDetailsQuery, GetPdpDetailsQueryVariables>(GetPdpDetailsDocument, options);
      }
export function useGetPdpDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPdpDetailsQuery, GetPdpDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPdpDetailsQuery, GetPdpDetailsQueryVariables>(GetPdpDetailsDocument, options);
        }
export type GetPdpDetailsQueryHookResult = ReturnType<typeof useGetPdpDetailsQuery>;
export type GetPdpDetailsLazyQueryHookResult = ReturnType<typeof useGetPdpDetailsLazyQuery>;
export type GetPdpDetailsQueryResult = Apollo.QueryResult<GetPdpDetailsQuery, GetPdpDetailsQueryVariables>;
export const GetPdpStatusDocument = gql`
    query getPdpStatus($memberid: String, $pdp_year: String) {
  getPdpDetails(memberid: $memberid, pdp_year: $pdp_year) {
    pdp_status
    pdp_type
  }
}
    `;

/**
 * __useGetPdpStatusQuery__
 *
 * To run a query within a React component, call `useGetPdpStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPdpStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPdpStatusQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      pdp_year: // value for 'pdp_year'
 *   },
 * });
 */
export function useGetPdpStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetPdpStatusQuery, GetPdpStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPdpStatusQuery, GetPdpStatusQueryVariables>(GetPdpStatusDocument, options);
      }
export function useGetPdpStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPdpStatusQuery, GetPdpStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPdpStatusQuery, GetPdpStatusQueryVariables>(GetPdpStatusDocument, options);
        }
export type GetPdpStatusQueryHookResult = ReturnType<typeof useGetPdpStatusQuery>;
export type GetPdpStatusLazyQueryHookResult = ReturnType<typeof useGetPdpStatusLazyQuery>;
export type GetPdpStatusQueryResult = Apollo.QueryResult<GetPdpStatusQuery, GetPdpStatusQueryVariables>;
export const GetReferencesDocument = gql`
    query GetReferences {
  activity_title: getReferenceLookup(lookupName: "AUTO_11") {
    items {
      lookup_type
      key
      value
    }
  }
  activity_type: getReferenceLookup(lookupName: "Activity_Type#23-50") {
    items {
      lookup_type
      key
      value
    }
  }
  specific_req_label: getReferenceLookup(lookupName: "specific_requirement#23-50") {
    items {
      lookup_type
      key
      value
    }
  }
}
    `;

/**
 * __useGetReferencesQuery__
 *
 * To run a query within a React component, call `useGetReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetReferencesQuery, GetReferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferencesQuery, GetReferencesQueryVariables>(GetReferencesDocument, options);
      }
export function useGetReferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferencesQuery, GetReferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferencesQuery, GetReferencesQueryVariables>(GetReferencesDocument, options);
        }
export type GetReferencesQueryHookResult = ReturnType<typeof useGetReferencesQuery>;
export type GetReferencesLazyQueryHookResult = ReturnType<typeof useGetReferencesLazyQuery>;
export type GetReferencesQueryResult = Apollo.QueryResult<GetReferencesQuery, GetReferencesQueryVariables>;
export const GetReferencesPdpDocument = gql`
    query getReferencesPdp {
  pdp_ea_list: getReferenceLookup(lookupName: "AUTO_25") {
    items {
      lookup_type
      key
      value
    }
  }
  pdp_rp_list: getReferenceLookup(lookupName: "AUTO_26") {
    items {
      lookup_type
      key
      value
    }
  }
  pdp_mo_list: getReferenceLookup(lookupName: "AUTO_27") {
    items {
      lookup_type
      key
      value
    }
  }
}
    `;

/**
 * __useGetReferencesPdpQuery__
 *
 * To run a query within a React component, call `useGetReferencesPdpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReferencesPdpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReferencesPdpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetReferencesPdpQuery(baseOptions?: Apollo.QueryHookOptions<GetReferencesPdpQuery, GetReferencesPdpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReferencesPdpQuery, GetReferencesPdpQueryVariables>(GetReferencesPdpDocument, options);
      }
export function useGetReferencesPdpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReferencesPdpQuery, GetReferencesPdpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReferencesPdpQuery, GetReferencesPdpQueryVariables>(GetReferencesPdpDocument, options);
        }
export type GetReferencesPdpQueryHookResult = ReturnType<typeof useGetReferencesPdpQuery>;
export type GetReferencesPdpLazyQueryHookResult = ReturnType<typeof useGetReferencesPdpLazyQuery>;
export type GetReferencesPdpQueryResult = Apollo.QueryResult<GetReferencesPdpQuery, GetReferencesPdpQueryVariables>;
export const GetSearchActivityDocument = gql`
    query getSearchActivity($request: SearchActivityInput) {
  searchActivity(request: $request) {
    items {
      activity {
        id
        hoursGroup
        tags
        hours
        sessionSeriesStartDate
        sessionSeriesEndDate
        title
        providerName
        targetAudience
        hours
        requirements {
          name
          hours
        }
        sessions {
          state
        }
        deliveryMode
        providerType
        programLevels
      }
      score
    }
    total
    facets {
      name
      values {
        value
        count
      }
    }
  }
}
    `;

/**
 * __useGetSearchActivityQuery__
 *
 * To run a query within a React component, call `useGetSearchActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchActivityQuery({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useGetSearchActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchActivityQuery, GetSearchActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchActivityQuery, GetSearchActivityQueryVariables>(GetSearchActivityDocument, options);
      }
export function useGetSearchActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchActivityQuery, GetSearchActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchActivityQuery, GetSearchActivityQueryVariables>(GetSearchActivityDocument, options);
        }
export type GetSearchActivityQueryHookResult = ReturnType<typeof useGetSearchActivityQuery>;
export type GetSearchActivityLazyQueryHookResult = ReturnType<typeof useGetSearchActivityLazyQuery>;
export type GetSearchActivityQueryResult = Apollo.QueryResult<GetSearchActivityQuery, GetSearchActivityQueryVariables>;
export const GetSearchActivityByIdDocument = gql`
    query getSearchActivityById($activityid: String!) {
  searchActivity(request: {text: $activityid, includeFacets: false}) {
    items {
      activity {
        id
        hoursGroup
        tags
        hours
        ruralCpdYesNo
        description
        sessionSeriesStartDate
        sessionSeriesEndDate
        title
        providerName
        targetAudience
        eligibleForGrantsYesNo
        hasAnaestheticGrant
        hasEmergencyMedicineGrant
        hasObstetricGrant
        hasSurgeryGrant
        relevance
        learningOutcomes
        gpDomains
        specificRequirements
        programLevels
        curriculumContextualUnits {
          name
        }
        requirements {
          name
          hours
        }
        sessions {
          eventId
          state
          fromDateTime
          toDateTime
          venue
          postcode
        }
        contact {
          firstName
          lastName
          phone
          email
        }
        deliveryMode
      }
    }
  }
}
    `;

/**
 * __useGetSearchActivityByIdQuery__
 *
 * To run a query within a React component, call `useGetSearchActivityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchActivityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchActivityByIdQuery({
 *   variables: {
 *      activityid: // value for 'activityid'
 *   },
 * });
 */
export function useGetSearchActivityByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSearchActivityByIdQuery, GetSearchActivityByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchActivityByIdQuery, GetSearchActivityByIdQueryVariables>(GetSearchActivityByIdDocument, options);
      }
export function useGetSearchActivityByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchActivityByIdQuery, GetSearchActivityByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchActivityByIdQuery, GetSearchActivityByIdQueryVariables>(GetSearchActivityByIdDocument, options);
        }
export type GetSearchActivityByIdQueryHookResult = ReturnType<typeof useGetSearchActivityByIdQuery>;
export type GetSearchActivityByIdLazyQueryHookResult = ReturnType<typeof useGetSearchActivityByIdLazyQuery>;
export type GetSearchActivityByIdQueryResult = Apollo.QueryResult<GetSearchActivityByIdQuery, GetSearchActivityByIdQueryVariables>;
export const GetSearchActivitySuggestionsDocument = gql`
    query getSearchActivitySuggestions($text: String) {
  searchActivitySuggestions(text: $text)
}
    `;

/**
 * __useGetSearchActivitySuggestionsQuery__
 *
 * To run a query within a React component, call `useGetSearchActivitySuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchActivitySuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchActivitySuggestionsQuery({
 *   variables: {
 *      text: // value for 'text'
 *   },
 * });
 */
export function useGetSearchActivitySuggestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSearchActivitySuggestionsQuery, GetSearchActivitySuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchActivitySuggestionsQuery, GetSearchActivitySuggestionsQueryVariables>(GetSearchActivitySuggestionsDocument, options);
      }
export function useGetSearchActivitySuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchActivitySuggestionsQuery, GetSearchActivitySuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchActivitySuggestionsQuery, GetSearchActivitySuggestionsQueryVariables>(GetSearchActivitySuggestionsDocument, options);
        }
export type GetSearchActivitySuggestionsQueryHookResult = ReturnType<typeof useGetSearchActivitySuggestionsQuery>;
export type GetSearchActivitySuggestionsLazyQueryHookResult = ReturnType<typeof useGetSearchActivitySuggestionsLazyQuery>;
export type GetSearchActivitySuggestionsQueryResult = Apollo.QueryResult<GetSearchActivitySuggestionsQuery, GetSearchActivitySuggestionsQueryVariables>;
export const SearchBookmarksDocument = gql`
    query searchBookmarks($memberid: String, $keyword: String, $triennium: String, $limit: Int! = 20) {
  searchBookmarks(
    memberid: $memberid
    keyword: $keyword
    triennium: $triennium
    limit: $limit
  ) {
    items {
      sortkey
      internal_id
      partitionkey
      bookmarkkey
    }
  }
}
    `;

/**
 * __useSearchBookmarksQuery__
 *
 * To run a query within a React component, call `useSearchBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBookmarksQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      keyword: // value for 'keyword'
 *      triennium: // value for 'triennium'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSearchBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<SearchBookmarksQuery, SearchBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchBookmarksQuery, SearchBookmarksQueryVariables>(SearchBookmarksDocument, options);
      }
export function useSearchBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBookmarksQuery, SearchBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchBookmarksQuery, SearchBookmarksQueryVariables>(SearchBookmarksDocument, options);
        }
export type SearchBookmarksQueryHookResult = ReturnType<typeof useSearchBookmarksQuery>;
export type SearchBookmarksLazyQueryHookResult = ReturnType<typeof useSearchBookmarksLazyQuery>;
export type SearchBookmarksQueryResult = Apollo.QueryResult<SearchBookmarksQuery, SearchBookmarksQueryVariables>;
export const GetSearchContentDocument = gql`
    query getSearchContent($memberid: String!, $type: String!, $searchterm: String!, $limit: Int!, $from: Int!, $searchfields: [SearchFieldInput], $sessionstate: String!, $sessiondayofweek: [Int!]) {
  searchContent(
    memberid: $memberid
    type: $type
    searchterm: $searchterm
    limit: $limit
    from: $from
    searchfields: $searchfields
    sessionstate: $sessionstate
    sessiondayofweek: $sessiondayofweek
  ) {
    items {
      content {
        sortkey
        title
        session_series_start_date
        session_series_end_date
        target_audience
        contact {
          phone
          email
        }
      }
    }
    total
  }
}
    `;

/**
 * __useGetSearchContentQuery__
 *
 * To run a query within a React component, call `useGetSearchContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchContentQuery({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      type: // value for 'type'
 *      searchterm: // value for 'searchterm'
 *      limit: // value for 'limit'
 *      from: // value for 'from'
 *      searchfields: // value for 'searchfields'
 *      sessionstate: // value for 'sessionstate'
 *      sessiondayofweek: // value for 'sessiondayofweek'
 *   },
 * });
 */
export function useGetSearchContentQuery(baseOptions: Apollo.QueryHookOptions<GetSearchContentQuery, GetSearchContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchContentQuery, GetSearchContentQueryVariables>(GetSearchContentDocument, options);
      }
export function useGetSearchContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchContentQuery, GetSearchContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchContentQuery, GetSearchContentQueryVariables>(GetSearchContentDocument, options);
        }
export type GetSearchContentQueryHookResult = ReturnType<typeof useGetSearchContentQuery>;
export type GetSearchContentLazyQueryHookResult = ReturnType<typeof useGetSearchContentLazyQuery>;
export type GetSearchContentQueryResult = Apollo.QueryResult<GetSearchContentQuery, GetSearchContentQueryVariables>;
export const SaveBookmarkDocument = gql`
    mutation saveBookmark($memberid: String, $title: String, $internal_type: String, $internal_id: String, $notes: String, $url: String) {
  saveBookmark(
    memberid: $memberid
    title: $title
    internal_type: $internal_type
    internal_id: $internal_id
    notes: $notes
    url: $url
  ) {
    memberid
    internal_id
    internal_type
    title
  }
}
    `;
export type SaveBookmarkMutationFn = Apollo.MutationFunction<SaveBookmarkMutation, SaveBookmarkMutationVariables>;

/**
 * __useSaveBookmarkMutation__
 *
 * To run a mutation, you first call `useSaveBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveBookmarkMutation, { data, loading, error }] = useSaveBookmarkMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      title: // value for 'title'
 *      internal_type: // value for 'internal_type'
 *      internal_id: // value for 'internal_id'
 *      notes: // value for 'notes'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useSaveBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<SaveBookmarkMutation, SaveBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveBookmarkMutation, SaveBookmarkMutationVariables>(SaveBookmarkDocument, options);
      }
export type SaveBookmarkMutationHookResult = ReturnType<typeof useSaveBookmarkMutation>;
export type SaveBookmarkMutationResult = Apollo.MutationResult<SaveBookmarkMutation>;
export type SaveBookmarkMutationOptions = Apollo.BaseMutationOptions<SaveBookmarkMutation, SaveBookmarkMutationVariables>;
export const SaveHighlevelRequirementDocument = gql`
    mutation saveHighlevelRequirement($memberId: String, $hlrDetails: HLRActivityTypeDateRangeInput, $cpdTypes: [CpdTypeInput], $attachments: [AttachmentInput]) {
  saveHighlevelRequirement(
    memberId: $memberId
    hlrDetails: $hlrDetails
    cpd_types: $cpdTypes
    attachments: $attachments
  ) {
    title
  }
}
    `;
export type SaveHighlevelRequirementMutationFn = Apollo.MutationFunction<SaveHighlevelRequirementMutation, SaveHighlevelRequirementMutationVariables>;

/**
 * __useSaveHighlevelRequirementMutation__
 *
 * To run a mutation, you first call `useSaveHighlevelRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveHighlevelRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveHighlevelRequirementMutation, { data, loading, error }] = useSaveHighlevelRequirementMutation({
 *   variables: {
 *      memberId: // value for 'memberId'
 *      hlrDetails: // value for 'hlrDetails'
 *      cpdTypes: // value for 'cpdTypes'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useSaveHighlevelRequirementMutation(baseOptions?: Apollo.MutationHookOptions<SaveHighlevelRequirementMutation, SaveHighlevelRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveHighlevelRequirementMutation, SaveHighlevelRequirementMutationVariables>(SaveHighlevelRequirementDocument, options);
      }
export type SaveHighlevelRequirementMutationHookResult = ReturnType<typeof useSaveHighlevelRequirementMutation>;
export type SaveHighlevelRequirementMutationResult = Apollo.MutationResult<SaveHighlevelRequirementMutation>;
export type SaveHighlevelRequirementMutationOptions = Apollo.BaseMutationOptions<SaveHighlevelRequirementMutation, SaveHighlevelRequirementMutationVariables>;
export const SaveLearningInterestsDocument = gql`
    mutation SaveLearningInterests($memberid: String, $learning_interests: [LearningInterestInput]) {
  saveLearningInterests(
    memberid: $memberid
    learning_interests: $learning_interests
  ) {
    items {
      learning_interest_id
      learning_interest
      status
    }
    total
  }
}
    `;
export type SaveLearningInterestsMutationFn = Apollo.MutationFunction<SaveLearningInterestsMutation, SaveLearningInterestsMutationVariables>;

/**
 * __useSaveLearningInterestsMutation__
 *
 * To run a mutation, you first call `useSaveLearningInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveLearningInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveLearningInterestsMutation, { data, loading, error }] = useSaveLearningInterestsMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      learning_interests: // value for 'learning_interests'
 *   },
 * });
 */
export function useSaveLearningInterestsMutation(baseOptions?: Apollo.MutationHookOptions<SaveLearningInterestsMutation, SaveLearningInterestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveLearningInterestsMutation, SaveLearningInterestsMutationVariables>(SaveLearningInterestsDocument, options);
      }
export type SaveLearningInterestsMutationHookResult = ReturnType<typeof useSaveLearningInterestsMutation>;
export type SaveLearningInterestsMutationResult = Apollo.MutationResult<SaveLearningInterestsMutation>;
export type SaveLearningInterestsMutationOptions = Apollo.BaseMutationOptions<SaveLearningInterestsMutation, SaveLearningInterestsMutationVariables>;
export const SaveMemberAttachmentsDocument = gql`
    mutation SaveMemberAttachments($memberid: String, $attachmentKey: String, $attachments: [AttachmentInput]) {
  saveMemberAttachments(
    memberid: $memberid
    attachmentKey: $attachmentKey
    attachments: $attachments
  ) {
    __typename
  }
}
    `;
export type SaveMemberAttachmentsMutationFn = Apollo.MutationFunction<SaveMemberAttachmentsMutation, SaveMemberAttachmentsMutationVariables>;

/**
 * __useSaveMemberAttachmentsMutation__
 *
 * To run a mutation, you first call `useSaveMemberAttachmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveMemberAttachmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveMemberAttachmentsMutation, { data, loading, error }] = useSaveMemberAttachmentsMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      attachmentKey: // value for 'attachmentKey'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useSaveMemberAttachmentsMutation(baseOptions?: Apollo.MutationHookOptions<SaveMemberAttachmentsMutation, SaveMemberAttachmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveMemberAttachmentsMutation, SaveMemberAttachmentsMutationVariables>(SaveMemberAttachmentsDocument, options);
      }
export type SaveMemberAttachmentsMutationHookResult = ReturnType<typeof useSaveMemberAttachmentsMutation>;
export type SaveMemberAttachmentsMutationResult = Apollo.MutationResult<SaveMemberAttachmentsMutation>;
export type SaveMemberAttachmentsMutationOptions = Apollo.BaseMutationOptions<SaveMemberAttachmentsMutation, SaveMemberAttachmentsMutationVariables>;
export const SaveNotificationPreferencesDocument = gql`
    mutation saveNotificationPreferences($memberid: String, $notification_preferences: PreferenceInput) {
  saveNotificationPreferences(
    memberid: $memberid
    notification_preferences: $notification_preferences
  ) {
    cpd_updates
    session_reminders {
      enabled
      remind_before_days
    }
  }
}
    `;
export type SaveNotificationPreferencesMutationFn = Apollo.MutationFunction<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>;

/**
 * __useSaveNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useSaveNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNotificationPreferencesMutation, { data, loading, error }] = useSaveNotificationPreferencesMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      notification_preferences: // value for 'notification_preferences'
 *   },
 * });
 */
export function useSaveNotificationPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>(SaveNotificationPreferencesDocument, options);
      }
export type SaveNotificationPreferencesMutationHookResult = ReturnType<typeof useSaveNotificationPreferencesMutation>;
export type SaveNotificationPreferencesMutationResult = Apollo.MutationResult<SaveNotificationPreferencesMutation>;
export type SaveNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<SaveNotificationPreferencesMutation, SaveNotificationPreferencesMutationVariables>;
export const SavePdpDetailsDocument = gql`
    mutation savePdpDetails($memberid: String, $pdp_year: String, $pdp_details: PdpDetailInput) {
  savePdpDetails(
    memberid: $memberid
    pdp_year: $pdp_year
    pdp_details: $pdp_details
  ) {
    scope_of_practice
    goals
    general_comments
    quick_log_reflection_comments
    annual_review_and_reflection
    pdp_status
    commented_activities
    cpd_activity_key
    cpdActivityPlans {
      type
      tags
      hours
    }
    attachments {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
    pdp_type
    exempt
    current_situation
    reflection_culturally_save_practice
    hours_spent
  }
}
    `;
export type SavePdpDetailsMutationFn = Apollo.MutationFunction<SavePdpDetailsMutation, SavePdpDetailsMutationVariables>;

/**
 * __useSavePdpDetailsMutation__
 *
 * To run a mutation, you first call `useSavePdpDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePdpDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePdpDetailsMutation, { data, loading, error }] = useSavePdpDetailsMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      pdp_year: // value for 'pdp_year'
 *      pdp_details: // value for 'pdp_details'
 *   },
 * });
 */
export function useSavePdpDetailsMutation(baseOptions?: Apollo.MutationHookOptions<SavePdpDetailsMutation, SavePdpDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePdpDetailsMutation, SavePdpDetailsMutationVariables>(SavePdpDetailsDocument, options);
      }
export type SavePdpDetailsMutationHookResult = ReturnType<typeof useSavePdpDetailsMutation>;
export type SavePdpDetailsMutationResult = Apollo.MutationResult<SavePdpDetailsMutation>;
export type SavePdpDetailsMutationOptions = Apollo.BaseMutationOptions<SavePdpDetailsMutation, SavePdpDetailsMutationVariables>;
export const SaveQuickLogActivityDocument = gql`
    mutation SaveQuickLogActivity($memberid: String, $activitykey: String, $hours: Float!, $date: String, $title: String, $cpd_types: [CpdTypeInput], $program_levels: [String], $description: String, $attachments: [AttachmentInput]) {
  saveQuickLogActivity(
    memberid: $memberid
    activitykey: $activitykey
    points: 0
    hours: $hours
    date: $date
    title: $title
    cpd_types: $cpd_types
    program_levels: $program_levels
    description: $description
    attachments: $attachments
  ) {
    title
    activitykey
    __typename
  }
}
    `;
export type SaveQuickLogActivityMutationFn = Apollo.MutationFunction<SaveQuickLogActivityMutation, SaveQuickLogActivityMutationVariables>;

/**
 * __useSaveQuickLogActivityMutation__
 *
 * To run a mutation, you first call `useSaveQuickLogActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveQuickLogActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveQuickLogActivityMutation, { data, loading, error }] = useSaveQuickLogActivityMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      activitykey: // value for 'activitykey'
 *      hours: // value for 'hours'
 *      date: // value for 'date'
 *      title: // value for 'title'
 *      cpd_types: // value for 'cpd_types'
 *      program_levels: // value for 'program_levels'
 *      description: // value for 'description'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useSaveQuickLogActivityMutation(baseOptions?: Apollo.MutationHookOptions<SaveQuickLogActivityMutation, SaveQuickLogActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveQuickLogActivityMutation, SaveQuickLogActivityMutationVariables>(SaveQuickLogActivityDocument, options);
      }
export type SaveQuickLogActivityMutationHookResult = ReturnType<typeof useSaveQuickLogActivityMutation>;
export type SaveQuickLogActivityMutationResult = Apollo.MutationResult<SaveQuickLogActivityMutation>;
export type SaveQuickLogActivityMutationOptions = Apollo.BaseMutationOptions<SaveQuickLogActivityMutation, SaveQuickLogActivityMutationVariables>;
export const SubmitPdpDocument = gql`
    mutation submitPdp($memberid: String, $pdp_year: String) {
  submitPdp(memberid: $memberid, pdp_year: $pdp_year) {
    scope_of_practice
    goals
    general_comments
    quick_log_reflection_comments
    annual_review_and_reflection
    pdp_status
    commented_activities
    cpd_activity_key
    cpdActivityPlans {
      type
      tags
      hours
    }
    attachments {
      attachment_name
      attachment_type
      retrieval_key
      url
    }
    pdp_type
    exempt
    current_situation
    reflection_culturally_save_practice
    hours_spent
  }
}
    `;
export type SubmitPdpMutationFn = Apollo.MutationFunction<SubmitPdpMutation, SubmitPdpMutationVariables>;

/**
 * __useSubmitPdpMutation__
 *
 * To run a mutation, you first call `useSubmitPdpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitPdpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitPdpMutation, { data, loading, error }] = useSubmitPdpMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      pdp_year: // value for 'pdp_year'
 *   },
 * });
 */
export function useSubmitPdpMutation(baseOptions?: Apollo.MutationHookOptions<SubmitPdpMutation, SubmitPdpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitPdpMutation, SubmitPdpMutationVariables>(SubmitPdpDocument, options);
      }
export type SubmitPdpMutationHookResult = ReturnType<typeof useSubmitPdpMutation>;
export type SubmitPdpMutationResult = Apollo.MutationResult<SubmitPdpMutation>;
export type SubmitPdpMutationOptions = Apollo.BaseMutationOptions<SubmitPdpMutation, SubmitPdpMutationVariables>;
export const UpdateNotificationsDocument = gql`
    mutation updateNotifications($memberid: String, $notifications: [SubscriptionInput]) {
  updateNotifications(memberid: $memberid, notifications: $notifications) {
    total
    unread
    items {
      type
      reference_id
      notification_status
      startDate
      cpd_update_details {
        title
        description
        start_date
        end_date
        url
      }
    }
  }
}
    `;
export type UpdateNotificationsMutationFn = Apollo.MutationFunction<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>;

/**
 * __useUpdateNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsMutation, { data, loading, error }] = useUpdateNotificationsMutation({
 *   variables: {
 *      memberid: // value for 'memberid'
 *      notifications: // value for 'notifications'
 *   },
 * });
 */
export function useUpdateNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>(UpdateNotificationsDocument, options);
      }
export type UpdateNotificationsMutationHookResult = ReturnType<typeof useUpdateNotificationsMutation>;
export type UpdateNotificationsMutationResult = Apollo.MutationResult<UpdateNotificationsMutation>;
export type UpdateNotificationsMutationOptions = Apollo.BaseMutationOptions<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>;